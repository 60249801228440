export const BIO_GIBA_FAVERY = 'Giba Favery atua profissionalmente como baterista desde os 16 anos. Estudou bateria na: Drummers Collective (New York), na Berklee College of Music (Boston), no conservatório Souza Lima e no CLAM (Zimbo Trio), onde também foi professor por seis anos. É bacharel em percussão pela FAC-FITO, pós-graduado em música popular brasileira pela Faccamp e mestre em música com foco em práticas instrumentais e performance pela Unicamp.\
<br><br>\
Lecionou na E.M.T. (Escola de Música e Tecnologia), onde desenvolveu e coordenou o curso do IP&T (Instituto de Percussão e Tecnologia) por 20 anos. Foi professor de instrumento na Faculdade Cantareira por 13 anos, sob a coordenação do baterista americano Bob Wyatt.\
<br><br>\
Já tocou e gravou com nomes como: Anai Rosa, Ary Roland, Cacique Jazz Combo, Cauby Peixoto Célia, Carlos Patriolino, Celso Pixinga Quinteto, Christianne Neves, Derico Sciotti, Eduardo e Silvinha Araújo, Eugenia Melo e Castro, Fernanda Porto, Graça Cunha, Heartbreakers, Jair Oliveira, Jair Rodrigues, Jorginho do Trumpete, Luciana Mello, Marcinho Eiras, Michel Freidenson, Nouvelle Cuisine, Mozart Mello, Paulo Moura, Tony Osanah, Roberta Miranda, Sula Miranda, Sandy e Junior, Secos & Molhados, Sizão Machado, Soulfunk, Pollaco, Virginia Rosa, Walter Franco, Willie Verdaguer, Zerró Santos Big Band entre outros. Foi o baterista da banda que acompanhava Jair Rodrigues por 7 anos consecutivos até a sua morte em 2014, com shows realizados em diversos e importantes festivais de música nacionais e internacionais.\
<br><br>\
Na área instrumental possui seu projeto solo, onde já lançou dois cd´s, intitulados Hard Samba (2001) e Dupla Dinâmica (2006) em parceria com o baixista Celso Pixinga. Em 2013, desenvolveu o projeto Ponte Aérea com músicos paulistas e cariocas: Marcelo Elias (pianista - diretor musical do artista Pedro Mariano), André Rodrigues (baixo – in memoriam) e Afonso Claudio no saxofone, resultando na gravação do CD Ponte Aérea em 2014. Com o saxofonista Afonso Claudio participa frequentemente de apresentações em casas como Jazz nos Fundos e Jazz B, em tributos dedicados ao saxofonista americano John Coltrane com releituras de obras representativas do músico como: Love Supreme e Ballads. Também integra o quarteto Na Área com João Cristal no piano, Zeli Silva no baixo e Dino Barioni na guitarra em apresentações regulares no Jazz nos Fundos, São Cristovão e no Raiz Bar.\
<br><br>\
Ainda no instrumental compõe o Trio da Força formado pelo guitarrista Michel Leme e o baixista Glécio Nascimento com apresentações regulares no projeto Willi Willie Jam, onde no primeiro set tocam standards do jazz e da música brasileira com destaque para a improvisação e no segundo set, convidam os músicos presentes para participarem da jam session. \
<br><br>\
Participa do musical É Melhor ser Alegre do que Triste, um tributo a Vinicius de Moraes, com a participação das cantoras Jane Duboc, Veronica Ferriani, Grazi Medori e o ator Juan Alba. Também atua no musical  A Vida em Vermelho com os atores Leticia Sabatella e Fernando Alves Pinto.\
<br><br>\
Giba Favery integra bandas de destaque no cenário paulista: Havana Brasil, Heartbrakers e Banda Farinha Seca, onde regularmente se apresenta no Bourbon Street, eventos corporativos e no circuito SESC Brasil. \
<br><br>\
Como músico acompanhante trabalha atualmente com: Anaí Rosa, Dino Barioni Quarteto, Faiska Trio, Graça Cunha, Celso Pixinga, Mark Lambert e Vania Abreu. \
<br><br>\
	Na área didática gravou quatro vídeo-aulas pela Aprenda Música, intituladas: Melhore Sua Técnica, Rudimentos como Estudar e Aplicar Vol. 1 e 2 e Dupla Dinâmica, com Celso Pixinga: Conceitos, Estilos e Grooves de Contrabaixo e Bateria.';
export const BIO_MAGUINHO_ALCANTARA = 'Nascido em uma família de músicos, Maguinho Alcântara destacou-se muito cedo pela\
      sua técnica e desempenho excepcional em todos os estilos musicais. Essa versatilidade\
      faz dele um dos bateristas mais requisitado nos estúdios de gravação do Rio de Janeiro\
      e São Paulo.\
      <br>\
<br>\
      Entre gravações e shows Maguinho Alcântara tocou com Lô Borges, Fábio Jr., Simone,\
      Leila Pinheiro, Djavan, Gal Costa, Johnny Alf, Victor Biglione, Claudia Leite, Chico\
      Buarque, Chitãozinho & Xororó, Nico Assumpção, T-Saquare, Raul de Souza, Jean-Pierre\
      Zanella, George Benson e Chuck Berry.\
      Em trabalhos mais recentes – Sinfonia Samsung Rock, Tou Brasil do baixista Polonês\
      Wojtek Pilichowski, Tour Brasil 2018 Orquestra e Coral Andrea Bocelli, Grupo Old Friends\
      e Banda Todos Por Um, além de atuar como compositor de Trilhas Brancas pela\
      Produtora Bangaloo'

<footer class="footer">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="footer-widgets">
          <div class="container">
            <div class="row">
              <div class="col-lg-4 col-12 mb-5 footer__social-network-container">
                <span class="footer__social-network-description">siga nossas redes sociais</span>
                <a class="social-link" target="_blank" href="https://facebook.com/IPTBRASIL"><fa-icon class="footer__social-network-icon--first" [size]="'2x'" [icon]="faInstagram"></fa-icon></a>
                <a class="social-link" target="_blank" href="https://instagram.com/ipt_brasil"><fa-icon class="footer__social-network-icon" [size]="'2x'" [icon]="faFacebook"></fa-icon></a>
                <a class="social-link" target="_blank" href="https://www.youtube.com/channel/UCKlffvIRHwx2G0nEy4xEX6Q"><fa-icon class="footer__social-network-icon" [size]="'2x'" [icon]="faYoutube"></fa-icon></a>
              </div>
              <div class="col-lg-4 col-12 mb-5">
                <div class="footer__address">
                  <div class="col-12 no-gutters">
                    <fa-icon size="2x" class="footer__pin-icon" [icon]="faMapPin"></fa-icon>
                    <address class="footer__address-text">Av 11 de Junho, 476<br>Vila Clementino - São Paulo/SP</address>
                  </div>
                  <div class="col-12 no-gutters">
                    <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3656.0867887501913!2d-46.64362838561872!3d-23.601220184663035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5a309c458943%3A0x9420732aa531113!2sAv.%2011%20de%20Junho%2C%20476%20-%20Vila%20Clementino%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2004041-001!5e0!3m2!1spt-BR!2sbr!4v1599158516758!5m2!1spt-BR!2sbr"
                    class="map" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
                    tabindex="0"></iframe>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-12 mb-5">
                <div class="col-12 no-gutters">
                  <div class="phone">
                    <fa-icon [size]="'3x'" class="footer-phone mb-3" [icon]="faPhone"></fa-icon>
                    <span class="phone-number">WHATSAPP:<strong (click)="sendWhatsappMessage()">(11) 94454-0293</strong></span>
                  </div>
                </div>
                <div class="col-12 no-gutters">
                  <div class="email">
                    <fa-icon [size]="'3x'" class="footer-email mb-3" [icon]="faEnvelope"></fa-icon>
                    <span class="email-address">E-MAIL:<strong>secretaria@iptgibafavery.com.br</strong></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="copyright">
          <div class="container">
            <div class="row">
              <div class="col-12 no-gutters">
                <div id="footer-text" class="footer-text">
                  © Copyright 2020 Instituto de Percussão e Tecnologia
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { colorChange, focusPhoto, galleryChange } from 'src/app/animations/animations';
import { GalleryItemState } from 'src/app/enums/gallery-item-state.enum'
import { I18nMeta } from '@angular/compiler/src/i18n/i18n_ast';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { ModalService } from '../modal/modal.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export interface GalleryItem {
  path: string;
  name: string;
  focused: boolean;
  category: CourseCategory;
  bio: SafeHtml;
  instagramLink: string;
  offsetImage?: boolean;
  fixed?: boolean;
}


export enum CourseCategory {
  EXTENSION = 'EXTENSION',
  REGULAR = 'REGULAR'
}

@Component({
  selector: 'app-photo-gallery-events',
  templateUrl: './photo-gallery-events.component.html',
  styleUrls: ['./photo-gallery-events.component.scss'],
  animations: [focusPhoto, colorChange, galleryChange],
})
export class PhotoGalleryEventsComponent implements OnInit {

  _activeCategory: CourseCategory;
  faInstagram = faInstagram;
  faTimes = faTimes;
  visible: boolean;

  CourseCategory = CourseCategory;

  @ViewChild('galleryContainer') galleryContainer: ElementRef;

  constructor(private breakpointService: BreakpointService,
    private modalService: ModalService,
    private sanitizer: DomSanitizer) { }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  cards: CourseCard[] = [
    {
      clicked: false,
      hovered: false,
      redTitle: "cursos",
      grayTitle: "regulares",
      category: CourseCategory.REGULAR
    },
    {
      clicked: false,
      hovered: false,
      redTitle: "cursos",
      grayTitle: "de extensão",
      category: CourseCategory.EXTENSION
    },
    {
      clicked: false,
      hovered: false,
      redTitle: "práticas",
      grayTitle: "de banda",
      category: CourseCategory.REGULAR
    }
  ];

  events = [
    // {
    //   imagePath: "assets/finished-events/drum_show/Daniel Giba.jpg"
    // },
    // {
    //   imagePath: "assets/finished-events/drum_show/Edu Garcia e Giba.jpg"
    // },

    // {
    //   imagePath: "assets/finished-events/drum_show/fabiano live.jpg"
    // },
    // {
    //   imagePath: "assets/finished-events/drum_show/CELSO DE ALMEIDA E GUI.jpg"
    // },
    // {
    //   imagePath: "assets/finished-events/drum_show/cleverson giba.jpg"
    // },
    // {
    //   imagePath: "assets/finished-events/drum_show/DIEGO GIL FABIANO.jpg"
    // },
    {
      imagePath: "assets/finished-events/drum_show/casey.jpg"
    },
    {
      imagePath: "assets/finished-events/drum_show/CUCA 2.jpg"
    },
    // {
    //   imagePath: "assets/finished-events/drum_show/bruna live.jpg"
    // },
    {
      imagePath: "assets/finished-events/drum_show/PAGOTO FABIO EDU.jpg"
    },
    {
      imagePath: "assets/finished-events/drum_show/DIEGO GIL CLEVERSON.jpg"
    },

    {
      imagePath: "assets/finished-events/drum_show/FABIANO SANDRO.jpg"
    },
    {
      imagePath: "assets/finished-events/drum_show/mesa redonda rock.jpg"
    },

    // {
    //   imagePath: "assets/finished-events/drum_show/JUBA MESA.jpg"
    // },
    // {
    //   imagePath: "assets/finished-events/drum_show/ALBINO MESA.jpg"
    // },
    // {
    //   imagePath: "assets/finished-events/drum_show/MAURICIO MESA.jpg"
    // },
    {
      imagePath: "assets/finished-events/drum_show/Mario fabre2.jpg"
    },
    {
      imagePath: "assets/finished-events/drum_show/Vinícius barros.jpg"
    },
    {
      imagePath: "assets/finished-events/drum_show/carlos bala.jpg"
    },
    {
      imagePath: "assets/finished-events/drum_show/michel glecio giba.jpg"
    },
    {
      imagePath: "assets/finished-events/drum_show/Aquiles Kiko2.jpg"
    },
    {
      imagePath: "assets/finished-events/drum_show/max sallum douglas.jpg"
    },
    {
      imagePath: "assets/finished-events/drum_show/bruna Ramon.jpg"
    },
    // {
    //   imagePath: "assets/finished-events/drum_show/Fabre e Giba.jpg"
    // },
    // {
    //   imagePath: "assets/finished-events/drum_show/GIba max live.jpg"
    // },
    // {
    //   imagePath: "assets/finished-events/drum_show/GIba Sandro.jpg"
    // },
    // {
    //   imagePath: "assets/finished-events/drum_show/GIba zotarelli live.jpg"
    // },
    // {
    //   imagePath: "assets/finished-events/drum_show/GUI RAMON LIVE.jpg"
    // },
    // {
    //   imagePath: "assets/finished-events/drum_show/igor e gui.jpg"
    // },
    {
      imagePath: "assets/finished-events/drum_show/mesa redonda ensino.jpg"
    },
    {
      imagePath: "assets/finished-events/drum_show/Percio Celso.jpg"
    },
    {
      imagePath: "assets/finished-events/drum_show/igor giba.jpg"
    },
    // {
    //   imagePath: "assets/finished-events/drum_show/JÔNATAS GUI.jpg"
    // },
    // {
    //   imagePath: "assets/finished-events/drum_show/Jônatas.jpg"
    // },
    // {
    //   imagePath: "assets/finished-events/drum_show/MAGUINHO GUI.jpg"
    // },



    // {
    //   imagePath: "assets/finished-events/drum_show/Netinho incrível.jpg"
    // },
    // {
    //   imagePath: "assets/finished-events/drum_show/Pagoto live.jpg"
    // },
    // {
    //   imagePath: "assets/finished-events/drum_show/percio e Giba.jpg"
    // },
    // {
    //   imagePath: "assets/finished-events/drum_show/VINI LEANDRO GUI.jpg"
    // },
    {
      imagePath: "assets/finished-events/drum_show/ZOTARELI GUILHERME MAGUINHO.jpg"
    },
    {
      imagePath: "assets/finished-events/drum_show/bala encerramento.jpg"
    },
    {
      imagePath: "assets/finished-events/drum_show/DUDUPORTES.jpg"
    },
]
  ngOnInit(): void {
    this.activateCard(this.cards[0]);
  }

  shuffleArray(array: GalleryItem[]) {
    for (let i = array.length - 1; i > 1; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }

    return array;
  }

  @HostListener('window:scroll', ['$event'])
  getScroll() {
    const element = this.galleryContainer.nativeElement;
    var position = element.getBoundingClientRect();

    // checking for partial visibility
    if (position.top >= 0 && position.bottom <= window.innerHeight) {
      this.visible = true;
    }
  }

  redirectToSocialNetwork(link: string) {
    window.open(link);
  }

  handleMouseEnter(item: GalleryItem) {
    item.focused = true;
  }

  get isMobile() {
    return this.breakpointService.isMobile();
  }

  getGalleryChangeStatus(item: GalleryItem) {
    return item.category === this.activeCategory;
  }

  getWidth() {
    return (window.innerWidth * 90) / 100;
  }

  getHeight() {
    return (window.innerHeight * 50) / 100;
  }

  handleMouseLeave(item: GalleryItem) {
    item.focused = false;
  }

  isFocused(item: GalleryItem) {
    return item.focused ? GalleryItemState.HOVERED : GalleryItemState.UNHOVERED
  }

  get activeCategory(): CourseCategory {
    return this._activeCategory;
  }

  set activeCategory(category: CourseCategory) {
    this._activeCategory = category;
  }

  activateCard(card: CourseCard) {
    this.cards.forEach(card => {
      card.clicked = false;
    })

    card.clicked = true;
    this.activeCategory = this.cards.filter(x => x.clicked).map(x => x.category).find(x => x);
  }



}

export interface CourseCard {
  hovered: boolean;
  clicked: boolean;
  redTitle: string;
  grayTitle: string;
  category: CourseCategory;
}

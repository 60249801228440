import { Component, OnInit } from '@angular/core';
import { colorChange, focusPhoto, galleryChange } from 'src/app/animations/animations';
import { GalleryItemState } from 'src/app/enums/gallery-item-state.enum'
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { ModalService } from '../modal/modal.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

export interface GalleryItem {
  path: string;
  name: string;
  focused: boolean;
  category: CourseCategory;
  bio: SafeHtml;
  instagramLink: string;
}


export enum CourseCategory {
  EXTENSION = 'EXTENSION',
  REGULAR = 'REGULAR'
}

@Component({
  selector: 'app-course-gallery',
  templateUrl: './course-gallery.component.html',
  styleUrls: ['./course-gallery.component.scss'],
  animations: [focusPhoto, colorChange, galleryChange],
})
export class CourseGalleryComponent implements OnInit {

  _activeCategory: CourseCategory;
  faInstagram = faInstagram;
  faTimes = faTimes;

  CourseCategory = CourseCategory;

  constructor(private breakpointService: BreakpointService,
    private modalService: ModalService,
    private sanitizer: DomSanitizer) { }

 

  cards: CourseCategoryCard[] = [
    {
      clicked: false,
      hovered: false,
      redTitle: "cursos",
      grayTitle: "regulares",
      category: CourseCategory.REGULAR
    },
    {
      clicked: false,
      hovered: false,
      redTitle: "cursos",
      grayTitle: "de extensão",
      category: CourseCategory.EXTENSION
    },
  ];

 

  ngOnInit(): void {
    this.activateCard(this.cards[0]);
  }

  get activeCategory(): CourseCategory {
    return this._activeCategory;
  }

  set activeCategory(category: CourseCategory) {
    this._activeCategory = category;
  }

  activateCard(card: CourseCategoryCard) {
    this.cards.forEach(card => {
      card.clicked = false;
    })

    card.clicked = true;
    this.activeCategory = card.category;
  }



}

export interface CourseCategoryCard {
  hovered: boolean;
  clicked: boolean;
  redTitle: string;
  grayTitle: string;
  category: CourseCategory;
}


import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ipt-kids',
  templateUrl: './ipt-kids.component.html',
  styleUrls: ['./ipt-kids.component.scss']
})
export class IptKidsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

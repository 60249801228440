import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { faCog, faArrowDown, faChevronCircleDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { trigger, transition, style, animate } from '@angular/animations';
import { arrowJump, backgroundSlideShow, colorChange } from './animations/animations'
import { ModalService } from './components/modal/modal.service';
import { BreakpointService } from './services/breakpoint.service';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [arrowJump, backgroundSlideShow]
})
export class AppComponent implements OnInit {

  faTimes = faTimes;
  faWhatsapp = faWhatsapp;

  @ViewChild('mainBackground') mainBackground: TemplateRef<any>;
  @ViewChild('carouselInner') carouselInner: ElementRef;

  constructor(private modalService: ModalService,
    private breakpointService: BreakpointService) {

  }

  sendWhatsappMessage() {
    const nativeElement = this.carouselInner.nativeElement;

    if (nativeElement) {
      let image = nativeElement.querySelector(".carousel-item.active img")

      const text = `Olá! Tenho interesse em participar do ${image.getAttribute("alt")}`;
      window.open(`https://wa.me/5511944540293?text=${encodeURIComponent(text)}`);
    }
  }

  goToFlyers(){
    this.closeModal('workshop-horacio-el-negro-hernandez');
    let elem = document.getElementById('eventos');

    if(elem){
      elem.scrollIntoView();
    }
  }

  openPDF(type: string) {
    const nativeElement = this.carouselInner.nativeElement;

    if (nativeElement) {
      let url;
      switch (type) {
        case 'programacao':
          url = 'programacao_toque_de_mestre.pdf'
          break;
      }
      window.open(`/assets/documents/${url}`, "_blank");
    }
  }

  title = 'iptlanding';
  faChevronCircleDown = faChevronCircleDown;
  isLoop = false;
  statusState: string;
  cardHovered: boolean;
  imageIndex = 0;
  backgroundImages = [
    'assets/slideshow_escola/auditorio-1.jpg',
    'assets/slideshow_escola/recepcao.jpg',
    'assets/slideshow_escola/roland-hall.jpg',
    'assets/slideshow_escola/sala-1.jpg',
    'assets/slideshow_escola/sala-5.jpg',
    'assets/slideshow_escola/sala-6.jpg',
    'assets/slideshow_escola/sonor-1.jpg',
    'assets/slideshow_escola/sonor-3.jpg'
  ]


  ngOnInit() {
    const element = document.querySelector('head');
    element.scrollIntoView();
    setTimeout(() => this.onLoopState(), 0);
    setInterval(() => this.changeBackgroundImages(), 4000);

    setTimeout(() => this.openModal("workshop-horacio-el-negro-hernandez"), 0);
  }

  get isMobile() {
    return this.breakpointService.isMobile();
  }

  get isTablet() {
    return this.breakpointService.isTablet();
  }

  get isDesktop() {
    return this.breakpointService.isDesktop();
  }

  onAnimationDone(event: AnimationEvent): void {
    if (event['fromState'] !== 'void' && this.isLoop) {
      setTimeout(() => {
        this.statusState === ArrowAnimationStates.START_STATE ? this.statusState = ArrowAnimationStates.LOOP_STATE : this.statusState = ArrowAnimationStates.START_STATE;
      }, 0);
    }
  }

  isSlideActive(loopIndex: number) {
    return this.imageIndex === loopIndex;
  }

  onSlideShowStart(event: AnimationEvent) {
  }

  getUrl(index: number) {
    return `url("${this.backgroundImages[index]}")`;
  }

  changeBackgroundImages() {
    this.imageIndex = this.imageIndex === this.backgroundImages.length - 1 ? 0 : this.imageIndex + 1;
  }

  onLoopState() {
    this.isLoop = true;
    this.statusState = ArrowAnimationStates.LOOP_STATE;
  }

  setEndState() {
    this.isLoop = false;
    this.statusState = ArrowAnimationStates.END_STATE;
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  getWidth() {
    return document.documentElement.clientWidth - 100;
  }

}

export enum ArrowAnimationStates {
  LOOP_STATE = 'loop-state',
  START_STATE = 'start-state',
  END_STATE = 'end-state'
}

<app-modal [transparent]="true" *ngIf="false" [fullWidth]="isMobile" id="workshop-horacio-el-negro-hernandez">

  <div class="modal-close gtm-tag-fire-close-workshop-horacio-el-negro-hernandez" (click)="closeModal('workshop-horacio-el-negro-hernandez')">
    <fa-icon [icon]="faTimes" size="2x"></fa-icon>
  </div>

  <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">

    <!-- <ol class="carousel-indicators">
      <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
    </ol>  -->
    <div class="carousel-inner" #carouselInner>
      <div class="carousel-item active">
        <img class="d-block w-100" *ngIf="isDesktop" src="../assets/horacio/horacio-desktop.jpg" alt="workshop com Horacio El Negro Hernandez">
        <img class="d-block w-100" *ngIf="isTablet" src="../assets/horacio/horacio-tablet.jpg" alt="workshop com Horacio El Negro Hernandez">
        <img class="d-block w-100" *ngIf="isMobile" src="../assets/horacio/horacio-smartphone.jpg" alt="workshop com Horacio El Negro Hernandez">
      </div>
    </div>
    <!-- <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a> -->
  </div>
  <div style="text-align: center;">
    <a (click)="sendWhatsappMessage()"
      class="gtm-tag-fire-workshop-horacio-el-negro-hernandez carousel-action-button btn btn-success carousel-action-button--affirmative"><span
        class="affirmative-text">Entrar em contato</span>
      <fa-icon class="whatsapp-icon" [icon]="faWhatsapp" size="2x"></fa-icon>
    </a>
  </div>

</app-modal>

<app-landing-header></app-landing-header>
<div class="landing-main container-fluid">
  <div class="landing-main__container container">


    <div *ngFor="let item of backgroundImages; let i = index"
      [@backgroundSlideShow]="isSlideActive(i) ? 'active' : 'inactive'" [ngStyle]="{'background-image': getUrl(i)}"
      (@backgroundSlideShow.start)="onSlideShowStart($event)" class="landing-main__background"></div>
    <div class="landing-main__title-section">
      <h2 class="landing-main__white-title">O <span class="ipt-title">IP&T</span> está de
        volta!</h2>
      <h3 class="landing-main__red-title">venha fazer parte!</h3>
      <h4 class="landing-main__gray-subtitle">Instituto de Percussão e Tecnologia: uma escola de bateria e percussão sem
        comparação!</h4>
    </div>
  </div>
</div>


<div id="coordenacao" class="landing-timeline container-fluid" style="background-color: #1d1d1d;">
  <div class="landing-timeline__container container">
    <app-timeline></app-timeline>
  </div>
</div>

<div class="landing-course-section container-fluid" style="background-color: rgb(53, 53, 53);">
  <div class="landing-course-section__container container" style="background-color:rgb(53, 53, 53);">
    <app-course-gallery></app-course-gallery>
  </div>
</div>

<div id="nosso-time-cursos-regulares" class="landing-course-section container-fluid" style="background-color: #2f2f2f;">
  <div class="landing-course-section__container container" style="background-color: #2f2f2f;">
    <app-photo-gallery-regular></app-photo-gallery-regular>
  </div>
</div>

<div id="nosso-time-workshop-horacio-el-negro-hernandez" class="landing-course-section container-fluid"
  style="background-color: #2f2f2f;">
  <div class="landing-course-section__container container" style="background-color: #2f2f2f;">
    <app-photo-gallery-extension></app-photo-gallery-extension>
  </div>
</div>

<!-- <div id="nosso-time-monitores" class="landing-course-section container-fluid" style="background-color: #2f2f2f;">
  <div class="landing-course-section__container container" style="background-color: #2f2f2f;">
    <app-photo-gallery-monitors></app-photo-gallery-monitors>
  </div>
</div> -->

<div id="ipt-kids">
  <div>
    <app-ipt-kids></app-ipt-kids>
  </div>
</div>

<div id="eventos" class="landing-course-section container-fluid" style="background-color: #2f2f2f;">
  <div class="landing-course-section__container container" style="background-color: #2f2f2f;">
    <app-photo-gallery-events></app-photo-gallery-events>
  </div>
</div>

<div id="patrocinadores">
  <div>
    <app-sponsors></app-sponsors>
  </div>
</div>

<div id="footer" class="landing-footer container-fluid" style="background-color: #1d1d1d;">
  <div class="landing-footer__container container" style="background-color: #1d1d1d;">
    <app-footer></app-footer>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { colorChange } from 'src/app/animations/animations';
import { GalleryItemState } from 'src/app/enums/gallery-item-state.enum';
import { ModalService } from '../../modal/modal.service';
import { GalleryItem } from '../../photo-gallery-regular/photo-gallery-regular.component';

@Component({
  selector: 'app-extension-courses',
  templateUrl: './extension-courses.component.html',
  styleUrls: ['./extension-courses.component.scss'],
  animations: [colorChange]
})
export class ExtensionCoursesComponent implements OnInit {

  faTimes = faTimes;

  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
    this.extensionCourseCards.push(...this.allCourseCards.filter((item, ind, arr) => ind < 3))
  }

  loadCourses(){
    this.extensionCourseCards = this.allCourseCards;
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  extensionCourseCards: ExtensionCourseCard[] = []

  allCourseCards: ExtensionCourseCard[] = [
    {
      title: 'TÉCNICA DE MÃO',
      subtitle: 'MÓDULO I – BÁSICO',
      hovered: false,
      focused: false,
      imagePath: "assets/professores/giba_favery.jpg",
      teacher: "Giba Favery",
      program: "Curso básico de aperfeiçoamento técnico com foco no estudo de solos de caixa e aplicação\
      técnica de rudimentos",
      objective: 'Desenvolvimento da técnica de bateria através de estudos de caixa; leitura rítmica aplicada;\
      leitura e execução de rudimentos; sonoridade; postura – pinça / matched grip / traditional grip',
      methodology: [
        '150 Solos (C. Wilcoxon)',
        'Rudimentos Isolados',
        'Leitura e performance de solos na caixa',
      ],
      duration: '8 aulas',
      level: 'Alunos de nível médio/intermediário/avançado',
      maxParticipants: 12
    },
    {
      title: 'TÉCNICA DE MÃO',
      subtitle: 'MÓDULO II - INTERMEDIÁRIO',
      hovered: false,
      focused: false,
      teacher: "Giba Favery",
      imagePath: "assets/professores/giba_favery.jpg",
      program: 'Curso intermediário de aperfeiçoamento técnico com foco no estudo de solos de caixa e\
      aplicação técnica de: rudimentos, leitura rítmica e dinâmica.',
      objective: 'Desenvolvimento da técnica de bateria intermediária/avançada através de estudos de caixa;\
      leitura rítmica aplicada; leitura e execução de rudimentos; sonoridade; noções de dinâmica\
      aplicada; postura – pinça / matched grip / traditional grip;',
      methodology: [
        '14 Solos (J. Pratt)',
        'Rudimentos Isolados',
        'Leitura e performance de solos na caixa',
      ],
      duration: '8 aulas',
      level: 'Alunos de nível médio/intermediário/avançado',
      maxParticipants: 12
    },
    {
      title: 'TÉCNICA DE MÃO',
      subtitle: 'MÓDULO III – AVANÇADO',
      hovered: false,
      focused: false,
      imagePath: "assets/professores/giba_favery.jpg",
      teacher: 'Giba Favery',
      program: 'Curso avançado de aperfeiçoamento técnico com foco no estudo de solos de caixa de alta\
      complexidade técnica e interpretativa através de estudo envolvendo: rudimentos, leitura rítmica,\
      dinâmica e interpretação. ',
      objective: 'Desenvolvimento da técnica de bateria avançada através de estudos de caixa; leitura rítmica\
      aplicada; leitura e execução de rudimentos; sonoridade; noções de dinâmica aplicada e\
      interpretação; postura – pinça / matched grip / traditional grip;',
      methodology: [
        'Solos de caixa de nível avançado (R. Podemski / V. Firth)',
        'Rudimentos Isolados',
        'Leitura e interpretação através da performance de solos de caixa',
      ],
      duration: '8 aulas',
      level: 'Alunos de nível médio/intermediário/avançado',
      maxParticipants: 12
    }
  ]

  handleMouseEnter(item: GalleryItem) {
    item.focused = true;
  }

  handleMouseLeave(item: GalleryItem) {
    item.focused = false;
  }

  isFocused(item: GalleryItem) {
    return item.focused ? GalleryItemState.HOVERED : GalleryItemState.UNHOVERED
  }

  courseParticipate(card: ExtensionCourseCard) {
    window.open(this.buildWhatsappMessage(card.title, card.subtitle, card.teacher));
  }

  buildWhatsappMessage(title: string, subtitle: string, teacher: string){
    const BASE_WHATSAPP_LINK = 'https://wa.me/5511944540293?text=';
    return `${BASE_WHATSAPP_LINK} Olá, tenho interesse em participar do curso ${encodeURIComponent(title)} - ${encodeURIComponent(subtitle)}, ministrado pelo professor ${teacher}`
  }
}
export interface ExtensionCourseCard {
  title: string;
  subtitle: string;
  hovered: boolean;
  focused: boolean;
  teacher: string;
  program: string;
  objective: string;
  imagePath: string;
  methodology: string[];
  duration: string;
  level: string;
  maxParticipants: number;
}

<div class="custom-modal" [ngClass]="{'custom-modal--black': black}">
  <div class="custom-modal-body" [ngClass]="{
    'custom-modal-body--transparent': transparent, 
    'custom-modal-body--fullWidth': fullWidth,
    'custom-modal-body--black': black
  }">
      <ng-content></ng-content>
  </div>
</div>
<div class="custom-modal-background"></div>

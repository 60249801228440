export const BIO_RICARDO_BERTI = 'Nascido em Guarulhos, São Paulo, Ricardo iniciou seus estudos musicais aos 14 anos.\
Dentre as instituições em que se formou musicalmente estão:\
<br>\
- IP&T (Instituto de Percussão e tecnologia).\
<br>\
- ULM Tom Jobim (Universidade Livre de Música Tom Jobim).\
<br>\
- EMESP (Escola de Música do Estado de São Paulo).\
<br>\
- Faculdade Souza Lima Berklee, Bacharel em música.\
<br>\
<br>\
Tem como principal influência o Jazz e a Música Brasileira.\
<br>\
<br>\
Estudou bateria com os mestres: Lilian Carmona, Carlos Ezequiel, José Carlos \
Silva, Giba Favery, Willian Caran, Edu Ribeiro, Nenê, Bob Wyatt além de ter \
realizado intercâmbio com a Julliard School de Nova York, (orientação Carl Allen) e \
com o Conservatório Didier Lockwood, França. \
<br><br>\
Dentre os trabalhos que gravou, vale ressaltar:\
<br>\
- “Amanhecer” - ao vivo – Wagner Barbosa e Paula Mirhan.\
<br>\
- “Muito Prazer” – Keila Abeid.\
<br>\
- “Razão da Raça Rústica” – Matuto Moderno “V” – Matuto Moderno.\
<br>\
- “III Mostra Brasil Instrumental” - Grupo Bambu – Música: “Voltei”.\
<br>\
- “Descompassado”- João Marcondes Quinteto.\
<br>\
- ”Herança”- Henrique Mota.\
<br>\
- “ Ensamble Brasileiro” – Ensamble Brasileiro.\
<br><br>\
Participou de importantes festivais tais como:\
<br>\
- “ Festival Viola Bem Temperada”\
<br>\
- “I e II Festival Revista Batera”\
<br>\
- “II e III Modern Drummer Festival”\
<br>\
- “ Mostra Brasil Instrumental”\
<br>\
- “ Morro Stock”\
<br>\
- “Jambalaia Jazz Festival”\
<br>\
- “Jack Daniel´s In Concert” \
<br>\
- “Jazz Trumpet Festival" nas edições: 2014, 2015, 2016, 2017, 2018.\
<br>\
- “Festival América do Sul”\
<br>\
- “ I e II Mostra de Jazz EMESP”\
<br>\
- “I, II e III Festival Movimento Elefantes”\
<br>\
<br>\
Dividiu palco com importantes nomes da música:\
<br>\
Arturo Sandoval, Jon Faddis, Byron Striplin, Allen Vizzuti, Erick Miyashiro, \
Rodrigo Ursaia, Fernando Corrêa, Djalma Lima, Lupa Santiago, Vitor Alcantara, \
Adylson Godoy, Luis Melo, Ricardo Castellanos, Paulo Tiné, Adriana Godoy, Tuca, \
Amilson Godoy, Adriana Peixoto, Renato Teixeira, Pena Branca, Pery Ribeiro entre \
outros. \
<br>\
<br>\
Atuou nos Musicais:\
<br>\
- “Aída”\
<br>\
- “Jekyll & Hide - O médico e o Monstro”\
<br>\
- “Tommy Ópera Rock”\
<br>\
- “Meu amigo Charlie Brown”\
<br>\
- “Mamma Mia”\
<br>\
- “Milton Nascimento- Nada será como antes”\
<br>\
- “Cauby, Cauby”\
<br>\
- “ Vingança” –O Musical\
<br>\
- “Cartola” – O Musical\
<br>\
- “Alegria, Alegria”\
<br>\
- “Garota de Ipanema, o musical da Bossa Nova"\
<br>\
<br>\
Além de participações na Orquestra Jovem Tom Jobim, Big Band EMESP, Big \
band Souza Lima, Big Band da Santa Marcelina, Banda Sinfônica Jovem do \
Estado de São Paulo e Wind Ensemble Orquestra. \
Atualmente trabalha como professor no IP&T e do Conservatório Souza Lima. \
Participa de trabalhos artísticos com Ensemble Brasileiro (Maestro. Paulo Tiné), \
Metallum Street , João Marcondes Quinteto, Henrique Mota Quarteto, Big Band \
S/A e Speakin\' Jazz Big Band. "';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingHeaderComponent } from './components/landing-header/landing-header.component';
import { PhotoGalleryRegularComponent } from './components/photo-gallery-regular/photo-gallery-regular.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TimelineComponent } from './components/timeline/timeline.component';
import { FooterComponent } from './components/footer/footer.component';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import { ModalComponent } from './components/modal/modal.component';
import { CourseGalleryComponent } from './components/course-gallery/course-gallery.component';
import { ExtensionCoursesComponent } from './components/course-gallery/extension-courses/extension-courses.component';
import { RegularCoursesComponent } from './components/course-gallery/regular-courses/regular-courses.component';
import { PhotoGalleryExtensionComponent } from './components/photo-gallery-extension/photo-gallery-extension.component';
import { IptKidsComponent } from './components/ipt-kids/ipt-kids.component';
import { SponsorsComponent } from './components/sponsors/sponsors.component';
import { PhotoGalleryMonitorsComponent } from './components/photo-gallery-monitors/photo-gallery-monitors.component';
import { PhotoGalleryEventsComponent } from './components/photo-gallery-events/photo-gallery-events.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingHeaderComponent,
    PhotoGalleryRegularComponent,
    TimelineComponent,
    FooterComponent,
    ModalComponent,
    CourseGalleryComponent,
    ExtensionCoursesComponent,
    RegularCoursesComponent,
    PhotoGalleryExtensionComponent,
    IptKidsComponent,
    SponsorsComponent,
    PhotoGalleryMonitorsComponent,
    PhotoGalleryEventsComponent
  ],
  imports: [
    BrowserModule,
    FontAwesomeModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    IvyCarouselModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

export const BIO_FABIANO_PAZ = 'Nascido em São Paulo no ano de 1982 ,Fabiano Paz começou a tocar bateria aos 17 anos embalado \
pelo cenário Hardcore no final da década de 90 . \
<br><br> \
 Em 2001 ingressou no IP&T onde estudou com os professores Mario Fabre e Giba Favery, dois \
anos depois abandonou a carreira de bancário para se dedicar 100% a música. Se formou no IP&T \
em 2008 após esse período estudou com os professores Christiano Rocha , Cuca Teixeira e Lilian \
Carmona . \
<br><br> \
Suas maiores influencias foram Travis Barker , Will Calhoun , Simon Phillips ,Steve Gadd , Milton \
Banana e Carlos Bala .\
<br><br>\
Como músico , tocou em diversas bandas como por exemplo: Verbbo , Elton John Oficial cover \
Brasil , Projeto Vinagrete , Big Band Aza entre outros e atualmente toca com a Banda Leela . \
Como Professor leciona aulas no IP&T desde 2008. \
<br><br>\
Além disso Fabiano Paz desenvolve um trabalho musical com crianças jovens e adultos com \
Síndrome de Down e outras deficiências. '
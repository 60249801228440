export const BIO_DUDA_NEVES = 'Nascido em 5 de Novembro de 1953 em São Paulo, Capital, é reconhecido pela crítica especializada e pelo público em geral, como um de nossos melhores instrumentistas.\
<br><br>\
A revista francesa “Jazz Hot” o apontou em 1990 como um dos melhores bateristas de Jazz do mundo e a revista “Bizz” como um de nossos melhores bateristas. \
<br><br>\
Toca Bateria profissionalmente desde os 12 anos de idade já tendo trabalhado com Simone, Belchior, Fábio Jr., Jorge Benjor, Tetê Espíndola, Tim Maia, Edu Lobo, Arrigo Barnabé entre outros. Morou em Nova York nos Estados Unidos na década de 80 onde tocou com grandes nomes do Jazz como Charlie Rouse, Don Salvador, Dom Um Romão, Nana Vasconcelos, Raul de Souza, Guilherme Vergueiro, Márcio Montarroyos, entre outros. \
<br><br>\
Tem na carreira solo 2 LPs gravados “Urucum” (90) e “Tempo Bom” (92) e 3 CDs “Crash” (95), “Temporal” (2000) e “Erupção” (2002). \
<br><br>\
Tem vídeo aulas para o ensino e aprendizado de Música tendo 7 títulos lançados desde 1990: “Bateria Total, “Ritmos Brasileiros”, “Rock & Blues”, “Reggae e Rap”, “Uma Hora de Técnica para Bateria”, “Esquizofônico”, “Aprenda Bateria em 10 Minutos”. Vídeo Aulas que, em conjunto com seu Método de Bateria Duda Neves / TKT, são usados em muitas Escolas de Música no Brasil. \
<br><br>\
Trabalhou e ministra Cursos de Música na Febem (Fundação do Bem Estar do Menor SP), Plimec (Plano de Integração do Menor na Comunidade SP), Unidades do Sesc (Serviço Social do Comércio), cursos livres no Colégio Visconde de Porto Seguro (SP), Colégio Logos (SP), professor de Música no Clube Paulistano (SP), Escola Municipal de Iniciação Artística de São Paulo, Centro Cultural São Paulo e Casas de Cultura da Prefeitura e do Estado de São Paulo.\
<br><br>\
Escreve matérias para revistas de Música Modern Drummer, Backstage, Batera, Música e Tecnologia.\
<br><br>\
Fez Direção Musical para Teatro – Aladim e a Lâmpada Maravilhosa de Carlos Meceni Mambembe 1975 e Happy End de Brecht e Weill com o grupo Pessoal do Despertar. Cria Jingles Publicitários e fez a Direção Musical do seriado da TV Cultura “São e Salvos”.\
<br><br>\
Já foi entrevistado na TV por Jô Soares, Adriane Galisteu, Serginho Groismann, programas da MTV, TV Cultura entre outros.\
<br><br>\
Faz shows com sua própria banda ”Duda Neves Bateria e Banda” além de gravações e apresentações com outros artistas em Festivais de Música no Brasil e no Exterior já tendo tocado em turnês na Alemanha, Itália, Suíça, França, Holanda e Estados Unidos.\
<br><br>\
É responsável pela área de Percussão do Projeto Guri em São Sebastião, litoral de São Paulo. Trabalha também como professor na ONG Verdescola, voltada para Educação de crianças carentes na Barra do Sahy em São Sebastião e é Coordenador da Área de Música do Colégio Mestre também em São Sebastião.';
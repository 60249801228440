<h2 class="course-options__title">
  <span>CURSOS DE EXTENSÃO - PROFESSORES CONVIDADOS</span>
</h2>

<div #galleryContainer>
  <ng-container *ngIf="visible">
    <ul class="row" *ngIf="true">
      <ng-container *ngFor="let item of teachers; let i = index">
        <li class="photo-gallery__item col-12 col-md-6 " (mouseenter)="handleMouseEnter(item)" (mouseleave)="handleMouseLeave(item)">

          <app-modal [black]="true" [id]="'professor-extensao-'+i">
            <!-- Dimensão mobile: considerar 462x850 como margem de segurança -->

            <div class="modal-close" (click)="closeModal('professor-extensao-'+i)">
              <fa-icon [icon]="faTimes" size="2x"></fa-icon>
            </div>

            <h5 class="red-title">{{item.name | uppercase }}</h5>
            <img [src]="item.path" class="bio-image">
            <div class="bio-section" [innerHTML]="item.bio"></div>
          </app-modal>

          <img [src]="item.path" [ngClass]="{'top-offset' : item.offsetImage}" class="gallery-image">

          <div [@focusPhoto]="isFocused(item)" class="photo-gallery__overlay">
            <div class="photo-gallery__text">
              <h4 class="photo-gallery__text-title">{{item.name}}</h4>
              <a class="photo-gallery__bio-link" (click)="openModal('professor-extensao-'+i)">ver biografia</a>
              <fa-icon (click)="redirectToSocialNetwork(item.instagramLink)" size="2x"
                class="photo-gallery__social-icon mt-2" [icon]="faInstagram"></fa-icon>
            </div>
          </div>
        </li>
      </ng-container>
    </ul>

  </ng-container>
</div>

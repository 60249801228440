import { Component, OnInit } from '@angular/core';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-landing-header',
  templateUrl: './landing-header.component.html',
  styleUrls: ['./landing-header.component.scss']
})
export class LandingHeaderComponent implements OnInit {

  constructor(private viewportScroller: ViewportScroller) { }

  ngOnInit(): void {
  }

  menuItems: MenuItem[] = [
    {
      description: "estrutura pedagógica",
      active: false,
      external: false,
      href: "#coordenacao",
    },
    {
      description: "cursos regulares",
      active: false,
      external: false,
      href: "#cursos-regulares",
    },
    {
      description: "cursos de especialização",
      active: false,
      external: false,
      href: "#cursos-de-extensao",
    },
    {
      description: "time ip&t",
      active: false,
      external: false,
      href: "#nosso-time-cursos-regulares",
    },
    {
      description: "ip&t kids",
      active: false,
      external: false,
      href: "#ipt-kids",
    },
    {
      description: "patrocinadores",
      active: false,
      external: false,
      href: "#patrocinadores",
    },
    {
      description: "contato",
      active: false,
      external: false,
      href: "#footer",
    }
  ]

  setItemAsActive(item: MenuItem) {
    this.menuItems.forEach(param => {
      param.active = false;
    });

    item.active = true;

    this.viewportScroller.scrollToAnchor(item.href.replace("#", ''))
  }

  faCog = faCog;
}

export class MenuItem {
  description: string;
  active: boolean;
  external: boolean;
  href: string;
}

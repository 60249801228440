<h2 class="course-options__title mb-4">
  <span>IP&T KIDS</span>
</h2>
<div class="course-card__container container">
  <div class="row">
    <div class="col-xs-12 col-sm-6 col-lg-4">
      <div class="course-card__image-flip image-flip" ontouchstart="this.classList.toggle('hover');">
        <div class="course-card__mainflip mainflip">
          <div class="course-card__frontside frontside" style="margin-bottom: 30px;">
            <div class="course-card__card card">
              <div class="course-card__card-body card-body text-center">
                <p class="course-card__card-title card-title">
                  Aulas individuais com a duração de 1 hora</p>
                <p class="course-card__card-subtitle card-subtitle">Para crianças de 4 a 8 anos
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-lg-4">
      <div class="course-card__image-flip image-flip" ontouchstart="this.classList.toggle('hover');">
        <div class="course-card__mainflip mainflip">
          <div class="course-card__frontside frontside" style="margin-bottom: 30px;">
            <div class="course-card__card card">
              <div class="course-card__card-body card-body text-center">
                <p class="course-card__card-title card-title">
                  Módulos</p>
                <p class="course-card__card-subtitle card-subtitle">O curso é dividido em 4 módulos com duração de 6 meses cada um</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-lg-4">
      <div class="course-card__image-flip image-flip" ontouchstart="this.classList.toggle('hover');">
        <div class="course-card__mainflip mainflip">
          <div class="course-card__frontside frontside" style="margin-bottom: 30px;">
            <div class="course-card__card card">
              <div class="course-card__card-body card-body text-center">
                <p class="course-card__card-title card-title">
                  desenvolvimento</p>
                <p class="course-card__card-subtitle card-subtitle"> Nos módulos o aluno vai desenvolver em diversos instrumentos os fundamentos musicais, teoria, prática e repertório.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="video-container">
        <iframe width="100%" height="380" src="https://www.youtube-nocookie.com/embed/KKot6TAcM0w?controls=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</div>

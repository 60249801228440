export const BIO_JONATAS_SANSAO: string = 'Jônatas teve seu primeiro contato com a bateria aos 8 anos de idade na\
  igreja, e logo iniciou seus estudos na Fundação das Artes de São Caetano do\
  Sul. Aos 12 anos tornou-se baterista da Big Band FASCS sob regência do\
  Maestro Antônio Carlos Neves Pinto, onde ficou por 5 anos, também estudou\
  no Centro de Estudos Musicais Tom Jobim (ULM).\
  <br>\
<br>\
  Jovem ainda, participou de vários festivais e workshops onde estudou\
  com importantes músicos do Brasil e do exterior, em 2006 foi convidado a\
  participar do workshop de improvisação com o Trombonista Vincent Gardner\
  (Jazz At Lincoln Center).\
  <br>\
<br>\
  Com 14 nos começou atuar na noite paulistana, se destacando com seu\
  talento, técnica e versatilidade, e desde então vem participando ativamente de\
  diversos festivais acompanhando diferentes artistas, fazendo gravações,\
  realizando workshops, aulas e tocando com os principais músicos do Brasil e\
  do exterior, tais como: Michel Leme, Arismar do Espírito Santo, Gilson\
  Peranzetta, Alexandre Mihanovich, Thiago Alves, Daniel D’Alcântara, Pepe\
  Cisneros (Cuba), Ohad Talmor (EUA), Sizão Machado, Amilton Godoy (Zimbo\
  Trio), Arthur Maia, Justin Vasques(EUA), Felipe Lamoglia (Cuba), Thiago do\
  Espírito Santo, Sandro Haick, Kiko Loureiro, Ed Neumeinstein (EUA),\
  Mestrinho, Vanessa Moreno, Mafalda Minozzi (Itália), Marcos Paiva, Julian Le\
  Prince(França), Ryan Keberle (EUA), Gabriel Grossi, Yaniel Matos (Cuba),\
  Beto Salvador, Wood Witt (EUA), Hector Costita (Arg), Lito Robledo, Alejandro\
  Aviles (EUA), Walmir Gil, Roberto Menescal, Jens Loh (ALE), entre outros.\
  Em 2015, Jônatas concedeu uma entrevista sobre bateria brasileira para\
  um trabalho de pesquisa feita pelo professor de bateria das faculdades Birch\
  Creek e Northen Lllinois University, ambas nos EUA.\
  <br>\
<br>\
  Atualmente Jônatas Sansão integra e participa de diversos grupos e\
  projetos, alguns deles são: Alexandre Mihanovich trio, Soundscape Big Band,\
  Sidiel Vieira Trio, Anna Setton, Jorginho Neto Trio, Thiago Alves Groove\
  Experience, Luiz Mello Trio e Quinteto, Ricardo Castellanos Trio (Cuba),\
  Blessed Jazz, Mestrinho, Vanessa Moreno, Bandoozonze, Septeto S.A., Trio-\
  Salomão-Pipoquinha-Sansão, Sofia Barion trio e desde 2011 se apresenta semanalmente com o On Jazz Trio (Davi Sansão e Nino Nascimento) no\
  Terraço Itália.\
  <br>\
<br>\
  Jônatas foi convidado a realizar alguns shows e concertos em grandes\
  festivais, como o Jazz Na Fábrica, onde se apresentou com seu quarteto,\
  estando na programação ao lado de grandes nomes como Richard Bona e\
  Cedar Walton. Também participou do Festival “Baterias Brasileiras” realizado\
  pelo Sesc, se apresentando com o seu quinteto. Com a SoundScape Big\
  Band recentemente realizou 2 concertos na Sala São Paulo e se apresentou\
  no Festival Jazz a La Calle no Uruguai.\
  <br>\
<br>\
  Em 2019, Jônatas fez uma série de shows na Suíça e Itália, ao lado de\
  Vanessa Moreno, Michael Pipoquinha e Salomão Soares.\
  Alguns trabalhos e albuns que já participou: DVD “Kiko Loureiro - White\
  Balance, "Sidiel Vieira Trio- Traços Urbanos", "Michel Leme Trio”, “Michel Leme Trio- Okan", “Michel Leme Trio- Fluxo”, “Anna Setton”, “Soundscape Big\
  Band-20 anos”, “Gabriel Grossi - Urbano”, “Alexandre Mihanovich e Michel\
  Leme - Jazz Terror”,\
  <br>\
<br>\
  "Concerto para Pixinguinha - Vânia Bastos e Marcos\
  Paiva", ganhador dos prêmios Profissionais da música 2017 e Melhor Disco\
  2016, “Vanessa Moreno- visita Elis” (com Tiago Costa e Fi Maróstica),\
  “Bandozonze- Boto”, “Gabriel Gaiardo - Live at Dissenso Studio”, “Trombone’s\
  Samba” (Jorginho Neto, Alevtina Wilcox, Corey Wilcox, participação Wycliffe\
  Gordon).\
  <br>\
<br>\
  Jônatas é endorser e usa: peles EVANS, baquetas PROMARK e\
  acessórios TORELLI e GAVAZZI CASE.';

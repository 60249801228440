<h2 class="course-options__title mb-5">
  <span>EVENTOS CONCLUÍDOS</span>
</h2>

<div #galleryContainer>
  <ng-container>
    <ul class="row" *ngIf="true">
      <ng-container *ngFor="let item of events; let i = index">
        <li class="col-12 col-md-6" (mouseenter)="handleMouseEnter(item)" (mouseleave)="handleMouseLeave(item)">
          <img [src]="item.imagePath" class="gallery-image">
        </li>
      </ng-container>
    </ul>

  </ng-container>
</div>

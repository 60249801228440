<div class="course-card__container">
  <div class="row">
    <div class="col-xs-12 col-sm-6 col-lg-4" *ngFor="let card of extensionCourseCards; let i = index">
      <app-modal [black]="true" class="course-card__modal" [id]="'curso-extensao-'+i">
        <h5 class="red-title text-center mb-4">{{card.title | lowercase }} - {{card.subtitle | lowercase}}</h5>

        <div class="course-card__modal-close" (click)="closeModal('curso-extensao-'+i)">
          <fa-icon [icon]="faTimes" size="2x"></fa-icon>
        </div>

        <img (mouseenter)="handleMouseEnter(card)" (mouseleave)="handleMouseLeave(card)" [src]="card.imagePath"
          class="bio-image">

        <div class="bio-section">
          <h5 class="red-title">professor(es)</h5>
          <div class="mb-4" [innerHTML]="card.teacher"></div>

          <h5 class="red-title">ementa</h5>
          <div class="mb-4" [innerHTML]="card.program"></div>

          <h5 class="red-title">objetivo</h5>
          <div class="mb-4" [innerHTML]="card.objective"></div>

          <h5 class="red-title">metodologia</h5>
          <ul *ngFor="let item of card.methodology">
            <li>
              {{item}}
            </li>
          </ul>
          <div class="mb-4" [innerHTML]=""></div>

          <h5 class="red-title">duração</h5>
          <div class="mb-4" [innerHTML]="card.duration"></div>

          <h5 class="red-title">nível</h5>
          <div class="mb-4" [innerHTML]="card.level"></div>

          <h5 class="red-title">número de vagas</h5>
          <div>até {{card.maxParticipants}} participantes</div>
        </div>


        <a class="bio-modal-close" (click)="courseParticipate(card)">
          <span>quero participar!</span>
        </a>
      </app-modal>

      <div class="course-card__image-flip image-flip" ontouchstart="this.classList.toggle('hover');">
        <div class="course-card__mainflip mainflip">
          <div class="course-card__frontside frontside">
            <div class="course-card__card card">
              <div class="course-card__card-body card-body text-center">
                <p class="course-card__card-title card-title" [@colorChange]="card.hovered">
                  {{card.title | uppercase}}</p>
                <p class="course-card__card-subtitle card-subtitle">{{card.subtitle | uppercase}}
                </p>
              </div>
            </div>
          </div>
          <div class="course-card__backside backside">
            <div class="course-card__card card">
              <div class="course-card__card-body card-body text-center">
                <div class="row mb-2">
                  <div class="col-6">
                    <span class="course-card__card-title card-title mt-0">Professor(es)</span>
                    <p class="course-card__card-text card-text">{{card.teacher}}</p>
                  </div>
                  <div class="col-6">
                    <span class="course-card__card-title card-title mt-0">Vagas</span>
                    <p class="course-card__card-text card-text">Até {{card.maxParticipants}}
                      participantes</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <span class="course-card__card-title card-title mt-0">Duração</span>
                    <p class="course-card__card-text card-text">{{card.duration}}</p>
                  </div>
                  <div class="col-6 course-card__participate-button" (click)="courseParticipate(card)">
                    <span class="course-card__participate-text">Quero participar!</span>
                  </div>
                </div>

              </div>
              <a class="course-card__details-link mb-3" (click)="openModal('curso-extensao-'+i)">mais
                informações</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <a class="course-participate__link" *ngIf="extensionCourseCards.length !== allCourseCards.length" (click)="loadCourses()">
      <span>ver mais</span>
    </a>
  </div>
</div>

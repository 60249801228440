export const BIO_PERCIO_SAPIA = 'Iniciou seus estudos de música no CLAM, Escola de música do Zimbo Trio, em 1976 com\
o professor João R. Ariza (Chumbinho). Neste periodo participou de inúmeros shows e\
festivais. Nesta época começou a ministrar aulas de bateria.\
Em 1986 voltou ao CLAM como professor, no mesmo ano tornou-se Coordenador do\
Departamento de bateria, onde foram formados muitos músicos que atuam até hoje no\
cenário nacional e internacional - Gilberto Favery, Alex Buck, Max Sallum, Luis Fernando\
Capano, dentre outros.\
<br>\
<br>\
Trabalhou também como professor no EMT, IPT. Foi colaborador da Revista Modern\
Drummer Brasil de 1997 a 2015. Neste período passou a ministrar Workshops e\
trabalhar com diversos artistas. Com Luiz Chaves participou do Projeto Cult Crowne,\
criado por Sergio Mamberti. Gravou, trilhas para cinema e propagandas, jingles, além\
de inúmeros CDs. Ao longo de sua carreira acompanhou diversos artistas, dentre os\
quais destacam-se: Leny Andrade, Raul de Souza, Jane Duboc, Fabiana Cozza, Alaíde\
Costa, Elton Medeiros, Eduardo Gudin, Jair Rodrigues, Daniela Mercury, Marcelo\
Mariano, Dani Cortazza, Christian Galves, Naylor "Proveta", Luis Salinas, Mario Boffa Jr.,\
Arismar do Espirito Santo, Tato Mafhuz, Paula Lima, Graça Cunha, Maricene Costa, Lito\
Robledo, Amilton Godoy e Nathan Marques.\
<br>\
<br>\
Zimbo Trio: a convite do próprio Rubinho Barsotti e Amilton Godoy participou dos\
ultimos 5 anos do trio com inúmeras gravações e apresentações na América do Sul\
(Argentina, Paraguai, Chile) China (Expo-Shangai 2010) e França. Com o Zimbo Trio\
ganhou o 23* Prêmio da Musica Brasileira como melhor trio instrumental de 2012.\
-Amilton Godoy Trio, com Amilton Godoy e Mario Andreotti.\
Atualmente faz workshops, participa de shows, gravações e ministra aulas em estúdio\
próprio em São Paulo e agora convidado a participar do IPT Giba Favery.';

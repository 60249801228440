export const BIO_EDU_GARCIA = 'Edu Garcia começou na música aos 7 anos de idade, como estudante de piano clássico no Conservatório de Música “ArsAtScientia do Brasil” (CMASB). Aos 10 anos de idade iniciou os estudos de bateria, vindo 13 anos mais tarde, tornar-se professor no mesmo Conservatório.Atualmente leciona aulas particulares e no Bateras Beat Pinheiros, escola referência no segmento. \
<br><br>\
Trabalhou de 2000 a 2002 como free-lance para a cantora Paula Hunter, tocando nos maiores rodeios do Brasil, ao lado de grandes artistas nacionais como Ivete Sangalo, Rick & Renner, entre outros. \
<br><br>\
Desde 2000, tem o projeto tributo à banda Pantera, chamada Unscarred, que se apresentou na antiga MTV, fez dois shows no Motorcycle Rock Cruise ao lado de Sepultura, Paul Dianno entre outros.\
<br><br>\
A partir de 2002 se dedicou a lecionar e a tocar na noite de SP com bandas pop. Em 2003 começou a trabalhar como drumtech e a focar em tocar estilos mais pesados (Heavy Metal, Hardcore, Thrash Metal). \
<br><br>\
Após longa passagem pela banda Threat, tocando em shows importantes e até um show internacional no maior festival de metal do mundo (Wacken Open Air), Edu Garcia volta-se totalmente para o mundo da bateria, dedicando-se 100% para as aulas e projetos, iniciando uma nova fase em 2013, lançando DVD didático sobre Skank beat, passando também a lecionar no Bateras Beat Pinheiros e tocando em duas novas bandas, One Arm Away e John Wayne.A banda John Wayne torna-se ocupação prioritária na agenda de 2013 a 2015, fazendo mais de 100 shows, incluindo a participação no Rock in Rio, e a gravação de um novo cd chamado Dois Lados – Parte I, lançado pela Deck Disc. (A agenda completa pode ser vista em www.edugarcia.com.br). \
<br><br>\
2016 será o ano de lançamentoda banda One Arm Away, e a continuação da tour de divulgação do cd Dois Lados – Parte 1, da banda John Wayne, além de show em comemoração aos 16 anos da banda Tributo ao Pantera, Unscarred.\
<br><br>\
Recentemente começou a fazer uma turnê de workshops com o guitarrista Rogerio Torres.\
<br><br>\
Em julho de 2016, Edu idealiza um projeto antigo com grandes amigos músicos chamado Defiance Brothers. Formado por Jean Patton (Guitarra), Felipe Andreoli (Baixo) e Thiago “monstrinho” Sierra (Vocal). É um projeto em homenagem a algumas bandas de metal que foram referência para a formação musical dos músicos do projeto mas além disso, um estudo e um desafio para tocar as músicas escolhidas. O projeto será todo registrado em vídeo ejá está disponível no canal www.youtube.com/defiancebrothers. \
<br><br>\
O projeto One Arm Away prepara-se para lançar o clipe da música “Dead Eyes” em julho/agosto de 2016.\
<br><br> \
Bandas: \
<br><br>\
- Unscarred: Pantera Tribute Brazil (2000 - atualmente); \
<br>\
- The Crow &The Crow’s band (2001 – 2002); \
<br>\
- Threat (2000 – 2012); \
<br>\
- Silas Fernandes Project (2009 – 2012);\
<br>\
- John Wayne (2013 - atualmente);\
<br>\
- One Arm Away (2014 - atualmente);\
<br>\
- Defiance Project (2016 – atualmente).\
<br>\
<br>\
Gravações realizadas:\
<br>\
<br>\
- CD: The Crow &TheCrow’sband (2001); \
<br>\
- CD: Threat“Heaven to Overthrow” (2007); \
<br>\
- DVD: “Drummin with Threat” (2008); \
<br>\
- EP: Threat-“Unbroken” (2009); \
<br>\
- DVD: Silas Fernandes “Live For Real”(2010); \
<br>\
- CD: Threat “Overcome”(2012); \
<br>\
- DVD: “Explaining The Secret Of Skank Beat” (2013); \
<br>\
- Single: John Wayne “Recomeço” (2014); \
<br>\
- CD: John Wayne “Dois Lados – Parte I” (2015);';
<h2 class="course-options__title mb-4">
  <span>PATROCINADORES</span>
</h2>
<div class="course-card__container container">
  <div class="row">
    <div class="grid-container container">
      <div *ngFor="let item of sponsorList" class="image-container">
        <img [src]="item.url" class="sponsor-image" [alt]="item.altText">
      </div>
    </div>
  </div>
</div>

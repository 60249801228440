import {
  AnimationTriggerMetadata,
  animate,
  style,
  transition,
  trigger,
  state,
  sequence,
} from '@angular/animations';
import { StepStatus } from '../enums/step-status.enum';
import { GalleryItemState } from '../enums/gallery-item-state.enum';

export const arrowJump: AnimationTriggerMetadata = trigger('arrowJump', [
  transition('void => *', [
    style({
      opacity: '0',
    }),
    animate('600ms', style({
      opacity: '1',
    }))
  ]),
  transition('* => loop-state', [
    animate('75ms', style({
      transform: 'translateY(-20px)'
    })),
    animate('400ms', style({
      transform: 'translateY(0px)'
    })),
    animate('75ms', style({
      transform: 'translateY(-20px)'
    })),
    animate('400ms', style({
      transform: 'translateY(0px)'
    })),
    animate('1500ms', style({
      transform: 'translateY(0px)'
    })),
  ]),
])

export const backgroundSlideShow: AnimationTriggerMetadata = trigger('backgroundSlideShow', [
  state('inactive', style({
    opacity: '0',
    visibility: 'visible',
  })),
  state('void', style({
    opacity: '0',
    visibility: 'hidden',
  })),
  state('active', style({
    opacity: '0.20',
    visibility: 'visible',
  })),
  transition('inactive => active', animate('1500ms ease-in')),
  transition('active => inactive', animate('1500ms ease-out')),
])

export const colorChange: AnimationTriggerMetadata = trigger('colorChange', [
  state('false', style({
    color: '#acacac',
  })),
  state('true', style({
    color: '#f44336',
  })),
  state('void', style({
    color: '#acacac',
  })),
  transition('* <=> *', animate('200ms ease-out')),
])

export const rowsAnimation = trigger('rowsAnimation', [
  state(`${StepStatus.INVISIBLE}`, style({ 'opacity': '0' })),
  transition(`* => ${StepStatus.VISIBLE}`, [
    style({ 'height': '*', 'opacity': '0', 'transform': 'translateX(-550px)', 'box-shadow': 'none' }),
    sequence([
      animate('.75s ease', style({
        'height': '*',
        'opacity': '.2',
        'transform': 'translateX(0)',
        'box-shadow': 'none',
      })),
      animate('.75s ease', style({
        height: '*',
        opacity: 1,
        transform: 'translateX(0)',
      })),
    ]),
  ])
]);

export const galleryChange = trigger('galleryChange', [
  state(`false`, style({ 'height': 0, 'position': 'absolute', 'opacity': 0 })),
  state(`void`, style({ 'height': 0, 'position': 'absolute', 'opacity': 0 })),
  transition(`* => true`, [
    style({ 'height': '*', 'position': 'relative' }),
    sequence([
      animate('.75s ease', style({
        'opacity': 1,
      })),
    ]),
  ]),
]);

export const focusPhoto = trigger('focusPhoto', [
  // ...
  state(GalleryItemState.HOVERED, style({
    opacity: 0.85
  })),
  state(GalleryItemState.UNHOVERED, style({
    opacity: 0
  })),
  transition(`${GalleryItemState.HOVERED} <=> ${GalleryItemState.UNHOVERED}`, [
    animate('0.3s')
  ]),
]);

export const BIO_PAULO_VICENTE = 'Entre gravações e shows acompanhou: Izzy Gordon, Sula Miranda, Agnaldo Rayol, Clara\
Moreno, Carla Casarim, Joyce Moreno, Dominguinhos, Anna Setton, Ceu, Jairzinho,\
Roberta Miranda, Jane Duboc, Luiz Carlos Sá, Renato Teixeira, Gabriel Sater, Danilo\
Caymmi, Ellen Oléria, Alma Thomas e mais.\
<br>\
<br>\
Paulinho também tocou ao lado de grandes artistas da música instrumental brasileira tais\
como: Toninho Horta, Lito Robledo, Alexandre Mihanovich, Wilson Teixeira, Filó Machado,\
Enéias Xavier, Daniel D’Alcantara, Luiz Mello, Silvia Góes, Roberto Sion, Yuri Poppoff,\
Bruno Cardozo, Claudio Roditti, Michel Leme, Sizão Machado, Thiago Espírito Santo,\
Vitor Alcântara, Ademir Junior, Bocato, Daniel Santiago, Salomão Soares, Michael\
Pipoquinha e outros.\
<br>\
<br>\
Esteve com nomes de grande expressão na cena instrumental mundial como os\
saxofonistas europeus Baptiste Herbin (FRA), Lukas Pfeil (ALE) e o contra-baixista\
alemão Jens Lo. Tocou com o músico cubano Felipe Lamoglia, e os artistas norte\
americanos: Ted Nash, Marcus Printup, Chad Lefkowitz, Jake Kelberman, Scott Belck,\
Trent Austin, Woody Witt, Ryan Keberle, Alejandro Aviles, Bob Shew, Davy Mooney, Alison\
Crocket, Ohad Talmor.\
<br>\
<br>\
Já se apresentou em festivais no Brasil e América Latina além de recente passagem pelos\
EUA em agosto de 2019. O trio composto por Paulinho Vicente, Felipe Silveira (pno) e\
Thiago Alves (c.baixo), realizou shows e masterlasses nas cidades de Denton, Austin,\
Houston e Passadena no estado do Texas e concertos na cidade de Nova Iorque.';

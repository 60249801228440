import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sponsors',
  templateUrl: './sponsors.component.html',
  styleUrls: ['./sponsors.component.scss']
})
export class SponsorsComponent implements OnInit {

  sponsorList: Sponsor[] = [
    {
      url: "../../../assets/sponsors/evans.png",
      altText: 'Evans'
    },
    {
      url: "../../../assets/sponsors/fsa.png",
      altText: 'Fsa'
    },
    {
      url: "../../../assets/sponsors/gretsch.png",
      altText: 'Gretsch'
    },
    {
      url: "../../../assets/sponsors/gsbproaudio.png",
      altText: 'Gsbproaudio'
    },
    {
      url: "../../../assets/sponsors/handdry.png",
      altText: 'Handdry'
    },
    {
      url: "../../../assets/sponsors/liverpool.png",
      altText: 'Liverpool'
    },
    {
      url: "../../../assets/sponsors/nagano.png",
      altText: 'Nagano'
    },
    {
      url: "../../../assets/sponsors/pearl.png",
      altText: 'Pearl'
    },
    {
      url: "../../../assets/sponsors/roland.png",
      altText: 'Roland'
    },
    {
      url: "../../../assets/sponsors/tama.png",
      altText: 'Tama'
    },
    {
      url: "../../../assets/sponsors/torelli.png",
      altText: 'Torelli'
    },
    {
      url: "../../../assets/sponsors/zildjian.png",
      altText: 'Zildjian'
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}

export interface Sponsor {
  url: string,
  altText: string
}

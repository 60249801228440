export const BIO_GUILHERME_MARQUES = 'Guilherme Marques é músico profissional com graduação, mestrado e doutorado em\
música pela Universidade Estadual de Campinas – UNICAMP. Atua nas áreas de música\
popular brasileira, música instrumental brasileira, música improvisada, jazz, trilha sonora,\
trabalhando como baterista, percussionista e compositor.\
<br>\
<br>\
Obteve destaque tocando com alguns grupos ligados à cena de música instrumental\
paulistana: À Deriva (www.musicaaderiva.com.br), grupo de música instrumental\
contemporânea, lançou seis CDs no mercado: “À Deriva” (2006); “À Deriva II” (2008);\
“Suíte do Náufrago” (2010), “Móbile” (2013), “O muro: rever o rumo” (2016) e “De\
senhores, baronesas, botos, urubus, cabritos e ovelhas” (2013) em parceria com o\
violonista e compositor gaúcho Cau Karam. O grupo Comboio, big band atuante na cena\
paulistana entre 2002 e 2016, cuja trajetória conta com três CDs lançados no mercado:\
“Sarado” (2002), “Comboio” (2005), e “Narrativas de Sobrevivência” (2011). Tocou e\
gravou com o quarteto instrumental KVAR (www.kvar.mus.br) que lançou em 2015 o\
disco “Kvar”. Toca no grupo Música de Selvagem (www.musicadeselvagem.com),\
dedicado ao estudo e performance de repertório autoral com foco na improvisação livre,\
cuja produção foi registrada nos discos “Música de Selvagem” (2016) e mais\
recentemente no “Vol. Único” (2018). Montou o grupo OlhoMágico, quinteto de música\
experimental com foco em repertório autoral e experiência com improvisação livre, cuja\
produção mais recente foi registrada no álbum “OlhoMágico” (2020). Colabora\
regularmente – tocando, compondo e participando dos processos criativos – com a\
companhia de teatro Les Commediens Tropicales (www.cialct.com.br) com a qual\
participou nas montagens dos espetáculos “uní-v oco s” (2014), “Guerra Sem Batalha”\
(2015), “MAUSER de Garagem” (2016), “Baal.material” (2016) e “Medusa Concreta”\
(2018), “MEDUSA in conSerto” (2020) além da performance “(ver[]ter)à deriva” que\
alcança mais 100 apresentações no Brasil e no exterior. Trabalha regularmente nos\
trabalhos nos processos criativos da iNSAiO Cia. de Arte (www.insaiociadearte.com.br),\
companhia dedicada à dança contemporânea, atuando como músico intérprete e\
compositor. Participou dos trabalhos “Abissal” (2016/2017) compondo, tocando bateria\
e live eletronics, atuando em mais de 30 apresentações; e no espetáculo “Ato Infinito”\
(2018/2019) compondo e operando a trilha ao vivo em diversos espaços culturais.\
Trabalhou com a Cia. Estrela D’Alva atuando no espetáculo “Ulisses À Deriva” (2015/2016)\
em diversas apresentações pelo estado de São Paulo. Trabalhou com a Cia. Auto-Retrato\
no processo criativo e apresentações do espetáculo “Origem Destino” (2012/2013).\
<br>\
<br>\
Como instrumentista independente já tocou e gravou em inúmeras situações e trabalhos\
distintos do jazz ao rock; do samba ao pop; passando pela música judaica, os diversos\
gêneros populares brasileiros, além de inúmeras experiências em gravações comerciais.\
Dentre os diversos trabalhos e artistas com quem tocou destaca-se: o pianista e\
compositor Vicente Falek e o projeto Obstinado (2020); o saxofonista Marcelo Coelho e\
o projeto Compondo na Rua (2018); Alexandre Prade e projeto Ek-Stasis(2015); a cantora\
Blubell com quem gravou o disco Eu sou do tempo em que a gente se telefonava (2012);\
além de intérpretes e instrumentistas como Celio Barros, Fernando Corrêa, Michel Leme,\
Oren Neiman (USA), Alan Plachta (Arg.), Edu Visconti, Fortuna, Hercules Gomes, Emílio\
Mendonça, Ana Archetti (Arg.), Alon Nechushtan (Israel), Nils Van Heertum (Bélgica),\
Neshama Carlebach (USA), Helcio Muller, Marcos Paiva, Frank London (USA), David\
Richards, Josué dos Santos, Vitor Alcântara, Rogerio Botter Maio Grupo, Rafael dos \
Santos, Nicole Borger, Isra Alien Duo (EUA), Banda Urbana, Banda Arruda Brasil, Camilo\
Carrara, Banda Klezmer Br., Sessa, Thiago França, Juliana Perdigão, Tim Bernardes, Luiza\
Lian, entre outros.\
<br>\
<br>\
Na área didática e acadêmica destaca-se seu trabalho como professor na Faculdade\
Cantareira em Sâo Paulo, onde é parte do corpo docente desde 2007, lecionando nos\
cursos de bacharelado e licenciatura em música. Sua atuação inclui trabalhos como\
professor de bateria, rítmica, improvisação, ritmos brasileiros, prática de conjunto,\
orientação em trabalhos de conclusão de curso (TCC) e participação em bancas de\
avaliação nos cursos de graduação e pós-graduação em educação musical. Leciona no\
terceiro setor como professor de bateria e prática de conjunto na ONG Associação Músico\
do Futuro em Taboão da Serra desde 2019. Apresentou e publicou trabalhos acadêmicos\
em congressos e revistas científicas nacionais que resultam de suas pesquisas nas áreas\
de bateria, percussão, improvisação, e estudos sobre identidade musical e cultura. '

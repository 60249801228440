import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { GalleryItemState } from 'src/app/enums/gallery-item-state.enum';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { CourseCategory } from '../course-gallery/course-gallery.component';
import { ModalService } from '../modal/modal.service';
import { CourseCard } from '../photo-gallery-regular/photo-gallery-regular.component';
import { BIO_CLEVERSON_SILVA } from '../../bios/bio-cleverson-silva';
import { BIO_GIBA_FAVERY } from '../../bios/bio-giba-favery';
import { BIO_BOB_WYATT } from '../../bios/bio-bob-wyatt';
import { BIO_CARLOS_BALA } from '../../bios/bio-carlos-bala';
import { BIO_VINICIUS_BARROS } from '../../bios/bio-vinicius-barros';
import { BIO_GUILHERME_MARQUES } from '../../bios/bio-guilherme-marques';
import { BIO_JONATAS_SANSAO } from '../../bios/bio-jonatas-sansao';
import { BIO_MAGUINHO_ALCANTARA } from '../../bios/bio-maguinho-alcantara';
import { BIO_PAULO_VICENTE } from '../../bios/bio-paulo-vicente';
import { BIO_PERCIO_SAPIA } from '../../bios/bio-percio-sapia';
import { BIO_CELSO_DE_ALMEIDA } from '../../bios/bio-celso-de-almeida';
import { BIO_DUDA_NEVES } from '../../bios/bio-duda-neves'
import { BIO_LILIAN_CARMONA } from '../../bios/bio-lilian-carmona'
import { BIO_MAX_SALLUM } from '../../bios/bio-max-sallum'
import { BIO_IGOR_WILLCOX } from '../../bios/bio-igor-willcox'
import { BIO_EDU_GARCIA } from '../../bios/bio-edu-garcia'
import { BIO_MAURICIO_ZOTTARELLI } from '../../bios/bio-mauricio-zottarelli'
import { BIO_SANDRO_HAICK } from '../../bios/bio-sandro-haick'
import { colorChange, focusPhoto, galleryChange, rowsAnimation } from 'src/app/animations/animations';

export interface GalleryItem {
  path: string;
  name: string;
  focused: boolean;
  category: CourseCategory;
  bio: SafeHtml;
  instagramLink: string;
  offsetImage?: boolean;
}

@Component({
  selector: 'app-photo-gallery-extension',
  templateUrl: './photo-gallery-extension.component.html',
  styleUrls: ['./photo-gallery-extension.component.scss'],
  animations: [rowsAnimation, focusPhoto, colorChange, galleryChange]
})
export class PhotoGalleryExtensionComponent implements OnInit {

  _activeCategory: CourseCategory;
  faInstagram = faInstagram;
  faTimes = faTimes;
  visible: boolean;

  CourseCategory = CourseCategory;

  @ViewChild('galleryContainer') galleryContainer: ElementRef;

  constructor(private breakpointService: BreakpointService,
    private modalService: ModalService,
    private sanitizer: DomSanitizer) { }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  shuffleArray(array: GalleryItem[]) {
    for (let i = array.length - 1; i > 1; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }

    return array;
  }

  cards: CourseCard[] = [
    {
      clicked: false,
      hovered: false,
      redTitle: "cursos",
      grayTitle: "regulares",
      category: CourseCategory.REGULAR
    },
    {
      clicked: false,
      hovered: false,
      redTitle: "cursos",
      grayTitle: "de extensão",
      category: CourseCategory.EXTENSION
    },
    {
      clicked: false,
      hovered: false,
      redTitle: "práticas",
      grayTitle: "de banda",
      category: CourseCategory.REGULAR
    }
  ];

  teachers: GalleryItem[] = [
    {
      name: "Giba Favery",
      path: "assets/professores/giba_favery.jpg",
      offsetImage: true,
      focused: false,
      category: CourseCategory.EXTENSION,
      bio: this.sanitizer.bypassSecurityTrustHtml(BIO_GIBA_FAVERY),
      instagramLink: 'https://instagram.com/gibafavery'
    },
    {
      name: "Bob Wyatt",
      path: "assets/professores/bob_wyatt.jpg",
      focused: false,
      category: CourseCategory.EXTENSION,
      offsetImage: true,
      bio: this.sanitizer.bypassSecurityTrustHtml(BIO_BOB_WYATT),
      instagramLink: 'https://instagram.com/bob.perdizes'
    },
    {
      name: "Guilherme Marques",
      path: "assets/professores/guilherme_marques.jpg",
      focused: false,
      offsetImage: true,
      category: CourseCategory.EXTENSION,
      bio: this.sanitizer.bypassSecurityTrustHtml(BIO_GUILHERME_MARQUES),
      instagramLink: 'https://instagram.com/guilherme_marques_d'
    },
    {
      name: "Carlos Bala",
      path: "assets/professores/carlos_bala.jpg",
      offsetImage: true,
      focused: false,
      category: CourseCategory.EXTENSION,
      bio: this.sanitizer.bypassSecurityTrustHtml(BIO_CARLOS_BALA),
      instagramLink: 'https://instagram.com/carlosbala_'
    },
    {
      name: "Maguinho Alcântara",
      path: "assets/professores/maguinho_alcantara.jpg",
      focused: false,
      offsetImage: true,
      category: CourseCategory.EXTENSION,
      bio: this.sanitizer.bypassSecurityTrustHtml(BIO_MAGUINHO_ALCANTARA),
      instagramLink: 'https://instagram.com/maguinhoalcantara'
    },
    {
      name: "Duda Neves",
      path: "assets/professores/duda_neves.jpg",
      focused: false,
      offsetImage: true,
      category: CourseCategory.EXTENSION,
      bio: this.sanitizer.bypassSecurityTrustHtml(BIO_DUDA_NEVES),
      instagramLink: 'https://instagram.com/dudadudaneves'
    },
    {
      name: "Lílian Carmona",
      path: "assets/professores/lilian_carmona.jpg",
      focused: false,
      offsetImage: true,
      category: CourseCategory.EXTENSION,
      bio: this.sanitizer.bypassSecurityTrustHtml(BIO_LILIAN_CARMONA),
      instagramLink: 'https://instagram.com/liliancarmonaoficial'
    },
    {
      name: "Celso de Almeida",
      path: "assets/professores/celso_de_almeida.jpg",
      focused: false,
      offsetImage: true,
      category: CourseCategory.EXTENSION,
      bio: this.sanitizer.bypassSecurityTrustHtml(BIO_CELSO_DE_ALMEIDA),
      instagramLink: 'https://instagram.com/celsodalmeida'
    },
    {
      name: "Jônatas Sansão",
      path: "assets/professores/jonatas_sansao.jpg",
      focused: false,
      offsetImage: true,
      category: CourseCategory.EXTENSION,
      bio: this.sanitizer.bypassSecurityTrustHtml(BIO_JONATAS_SANSAO),
      instagramLink: 'https://instagram.com/jonatassansao'
    },

    {
      name: "Paulo Vicente",
      path: "assets/professores/paulo_vicente.jpg",
      offsetImage: true,
      focused: false,
      category: CourseCategory.EXTENSION,
      bio: this.sanitizer.bypassSecurityTrustHtml(BIO_PAULO_VICENTE),
      instagramLink: 'https://instagram.com/vicente_paulinho'
    },
    {
      name: "Max Sallum",
      path: "assets/professores/max_sallum.jpg",
      focused: false,
      offsetImage: true,
      category: CourseCategory.EXTENSION,
      bio: this.sanitizer.bypassSecurityTrustHtml(BIO_MAX_SALLUM),
      instagramLink: 'https://instagram.com/maxsallum'
    },
    {
      name: "Pércio Sápia",
      path: "assets/professores/percio_sapia.jpg",
      focused: false,
      offsetImage: true,
      category: CourseCategory.EXTENSION,
      bio: this.sanitizer.bypassSecurityTrustHtml(BIO_PERCIO_SAPIA),
      instagramLink: 'https://instagram.com/perciosapia'
    },


    {
      name: "Vinicius Barros",
      path: "assets/professores/vinicius_barros.jpg",
      focused: false,
      category: CourseCategory.EXTENSION,
      offsetImage: true,
      bio: this.sanitizer.bypassSecurityTrustHtml(BIO_VINICIUS_BARROS),
      instagramLink: 'https://instagram.com/viniperc'
    },

    {
      name: "Igor Willcox",
      path: "assets/professores/igor_willcox.jpg",
      focused: false,
      offsetImage: true,
      category: CourseCategory.EXTENSION,
      bio: this.sanitizer.bypassSecurityTrustHtml(BIO_IGOR_WILLCOX),
      instagramLink: 'https://instagram.com/igorwillcox'
    },
    {
      name: "Edu Garcia",
      path: "assets/professores/edu_garcia.jpg",
      focused: false,
      offsetImage: true,
      category: CourseCategory.EXTENSION,
      bio: this.sanitizer.bypassSecurityTrustHtml(BIO_EDU_GARCIA),
      instagramLink: 'https://instagram.com/edugarciadrum'
    },
    {
      name: "Mauricio Zottarelli",
      path: "assets/professores/mauricio_zottarelli.jpg",
      focused: false,
      offsetImage: true,
      category: CourseCategory.EXTENSION,
      bio: this.sanitizer.bypassSecurityTrustHtml(BIO_MAURICIO_ZOTTARELLI),
      instagramLink: 'https://instagram.com/mzdrums'
    },
    {
      name: "Sandro Haick",
      path: "assets/professores/sandro_haick.jpg",
      focused: false,
      offsetImage: true,
      category: CourseCategory.EXTENSION,
      bio: this.sanitizer.bypassSecurityTrustHtml(BIO_SANDRO_HAICK),
      instagramLink: 'https://instagram.com/sandrohaick'
    },
  ]

  ngOnInit(): void {
    this.activateCard(this.cards[0]);
  }

  @HostListener('window:scroll', ['$event'])
  getScroll() {
    const element = this.galleryContainer.nativeElement;
    var position = element.getBoundingClientRect();

    // checking for partial visibility
    if (position.top >= 0 && position.bottom <= window.innerHeight) {
      this.visible = true;
    }
  }

  redirectToSocialNetwork(link: string) {
    window.open(link);
  }

  handleMouseEnter(item: GalleryItem) {
    item.focused = true;
  }

  get isMobile() {
    return this.breakpointService.isMobile();
  }

  getGalleryChangeStatus(item: GalleryItem) {
    return item.category === this.activeCategory;
  }

  getWidth() {
    return (window.innerWidth * 90) / 100;
  }

  getHeight() {
    return (window.innerHeight * 50) / 100;
  }

  handleMouseLeave(item: GalleryItem) {
    item.focused = false;
  }

  isFocused(item: GalleryItem) {
    return item.focused ? GalleryItemState.HOVERED : GalleryItemState.UNHOVERED
  }

  get activeCategory(): CourseCategory {
    return this._activeCategory;
  }

  set activeCategory(category: CourseCategory) {
    this._activeCategory = category;
  }

  activateCard(card: CourseCard) {
    this.cards.forEach(card => {
      card.clicked = false;
    })

    card.clicked = true;
    this.activeCategory = this.cards.filter(x => x.clicked).map(x => x.category).find(x => x);
  }



}

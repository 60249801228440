<div class="landing-header">

  <nav class="navbar navbar-expand-lg navbar-dark container">
    <a class="navbar-brand" href="#">
      <img class="landing-header__logo" src="../assets/ipt_logo.png">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      Menu
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto">
        <li *ngFor="let item of menuItems; let i = index" class="nav-item landing-header__nav-item">
          <a class="nav-link landing-header__nav-link" (click)="setItemAsActive(item)"
            [ngClass]="{'landing-header__nav-link--active': item.active}">{{item.description | uppercase}}</a>
        </li>
      </ul>
    </div>
  </nav>

  <!-- <div class="landing-header__container container">
    <div class="landing-header__logo-container float-left col-2">
      <img class="landing-header__logo" src="../assets/ipt_logo.png">
    </div>
    <div class="navbar navbar-expand-lg">
      <ul class="nav landing-header__nav col-10 right">

      </ul>
    </div>
  </div> -->
</div>

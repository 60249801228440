export const BIO_BRUNA_BARONE = 'Bruna Barone teve seu primeiro contato com a música aos seis anos de idade com\
aulas de piano.\
<br><br>\
 O interesse pela bateria sempre existiu mas foi aos 14 anos que iniciou seus \
estudos com Giba Favery no IP&T (Instituto de Percussão e Tecnologia), onde se formou \
em 2010. Ainda no IP&T, estudou com Christiano Rocha por mais três anos. \
<br><br>\
 Interessada em ampliar seu conhecimento pelos ritmos brasileiros, estudou com \
Vera Figueiredo, no IBVF (Instituto de Bateria Vera Figueiredo), por cinco anos. Também \
teve aulas particulares com Pascoal Meirelles, Ramon Montagner, Kiko Freitas e retomou \
os estudos de piano com Debora Gurgel. \
<br><br>\
 Nos EUA participou, em Cleveland e Chicago, do Drum Fantasy Camp, assistindo \
aulas e masterclasses com Dave Weckl, Peter Erskine, Tommy Igoe, Thomas Lang e Jojo \
Mayer, Antonio Sanchez, Virgil Donato e Mark Guiliana. Em Boston, participou do Berklee \
Percussion Festival, estudando com Robert Kaufman, Horacio “El Negro” Hernandez, \
Pete Lockett, Casey Scheuerell, Ron Savage, Michael Ringquist, Ernesto Diaz e Jerry \
Leake. Em Nova Iorque teve aulas particulares com John Riley, Dom Famularo, Jimmy \
Cobb, Larry Lelli e Mauricio Zottarelli. \
<br><br>\
 Convidada por Vera Figueiredo, apresentou-se na 13ª edição do Batuka! Brasil \
International Drum Fest, onde também se apresentaram Dom Famularo, Damien Schimitt, \
Robby Ameen, Aquiles Priester, Quintino Cinalli, Fabiana Fonseca e Renato Martins. \
<br><br>\
 Acompanhou grandes nomes do sapateado nacional e internacional em três edições do Brasil Tap Festival.\
 Em 2018 apresentou-se na primeira edição on-line do festival Girls On Drums. \
 Nos últimos anos, vem trabalhando no teatro musical. Foi baterista titular dos \
espetáculos Cole Porter - ele nunca disse que me amava, Chaplin - o musical, Cantando \
na Chuva, Raia 30 - o musical, Crazy For You e Cabaret. Também teve participações \
tocando em Ayrton Senna – o musical, O Mágico de Óz, Rock in Rio - o musical e Cabaret \
Luxúria. \
<br><br>\
 Bruna também atua como freelancer se apresentando em diversos shows e eventos.\
 Na área da didática, lecionou no IBVF, British College of Brazil e fez parte coluna \
de estudos da revista Modern Drummer Brasil. Atualmente é professora do IP&T e \
ministra aulas particulares em seu estúdio. ';
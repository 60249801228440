<div class="container">
  <h2 class="timeline__title">
    <!-- <span>direção</span> -->
  </h2>
  <div class="row">
    <div class="col-lg-12">
      <ul class="timeline">
        <li #step *ngFor="let step of steps; let i = index;" [ngClass]="{'timeline-inverted': i % 2 !== 0}">
          <div class="timeline-image">
            <img class="img-circle img-responsive" width="200px" [src]="step.imagePath" alt="">
          </div>
          <div [@rowsAnimation]="step.status" class="timeline-panel">
            <div class="timeline-heading">
              <span class="subheading">{{step.subtitle | uppercase}}</span>
              <h4>{{step.title | titlecase}}</h4>
            </div>
            <div class="timeline-body">
              <p class="text-muted">
                {{step.description}}
              </p>
            </div>
          </div>
          <ng-container *ngIf="i < steps.length - 1">
            <div class="line"></div>
          </ng-container>
        </li>
      </ul>
    </div>
  </div>
</div>

import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { GalleryItemState } from 'src/app/enums/gallery-item-state.enum'
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { ModalService } from '../modal/modal.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { CourseCard, CourseCategory, GalleryItem } from '../photo-gallery-regular/photo-gallery-regular.component';
import { BIO_CARLOS_BALA } from 'src/app/bios/bio-carlos-bala';
import { colorChange, focusPhoto, galleryChange } from 'src/app/animations/animations';

@Component({
  selector: 'app-photo-gallery-monitors',
  templateUrl: './photo-gallery-monitors.component.html',
  styleUrls: ['./photo-gallery-monitors.component.scss'],
  animations: [focusPhoto, colorChange, galleryChange]
})
export class PhotoGalleryMonitorsComponent implements OnInit {

  _activeCategory: CourseCategory;
  faInstagram = faInstagram;
  faTimes = faTimes;
  visible: boolean;

  CourseCategory = CourseCategory;

  @ViewChild('galleryContainer') galleryContainer: ElementRef;

  constructor(private breakpointService: BreakpointService,
    private modalService: ModalService,
    private sanitizer: DomSanitizer) { }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  teachers: GalleryItem[] = [
    {
      name: "Victor Alencar",
      path: "assets/professores/victor_alencar.jpg",
      fixed: true,
      focused: false,
      category: CourseCategory.REGULAR,
      bio: this.sanitizer.bypassSecurityTrustHtml(''),
      instagramLink: 'https://instagram.com/victoralencardrums'
    },
  ]

  ngOnInit(): void {
  }

  @HostListener('window:scroll', ['$event'])
  getScroll() {
    const element = this.galleryContainer.nativeElement;
    var position = element.getBoundingClientRect();

    // checking for partial visibility
    if (position.top >= 0 && position.bottom <= window.innerHeight) {
      this.visible = true;
    }
  }

  redirectToSocialNetwork(link: string) {
    window.open(link);
  }

  handleMouseEnter(item: GalleryItem) {
    item.focused = true;
  }

  get isMobile() {
    return this.breakpointService.isMobile();
  }

  getGalleryChangeStatus(item: GalleryItem) {
    return item.category === this.activeCategory;
  }

  getWidth() {
    return (window.innerWidth * 90) / 100;
  }

  getHeight() {
    return (window.innerHeight * 50) / 100;
  }

  handleMouseLeave(item: GalleryItem) {
    item.focused = false;
  }

  isFocused(item: GalleryItem) {
    return item.focused ? GalleryItemState.HOVERED : GalleryItemState.UNHOVERED
  }

  get activeCategory(): CourseCategory {
    return this._activeCategory;
  }

  set activeCategory(category: CourseCategory) {
    this._activeCategory = category;
  }
}
<h2 class="course-options__title mb-4">
    <span>CURSOS REGULARES</span>
</h2>

<section id="cursos-regulares" class="course-card pb-5">
    <app-regular-courses></app-regular-courses>
</section>

<h2 class="course-options__title mb-4">
    <span>CURSOS DE ESPECIALIZAÇÃO</span>
</h2>

<section id="cursos-de-extensao" class="course-card pb-5">
    <app-extension-courses></app-extension-courses>
</section>

export const BIO_MAURICIO_ZOTTARELLI = 'Nascido no Brasil, o baterista / compositor residente em Nova York Mauricio Zottarelli tem estado na vanguarda do jazz, música latina e brasileira por muitos anos. Artista indicado ao Grammy, Zottarelli foi chamado de “esplêndido”, “excelente”, “dinâmico” e “extraordinário” por muitas publicações musicais de prestígio em todo o mundo, como as revistas JazzTimes e Downbeat. Mauricio vem de uma família musical e nasceu em Santos, SP, Brasil, mas cresceu em Rio Claro, SP em um ambiente altamente musical. Seus primeiros anos como músico profissional consistiram em muitas apresentações em seu país natal, bem como em estúdio e sessões de trabalho com diferentes artistas e bandas. Ao mesmo tempo, formou-se em Ciência da Computação e concluiu seus estudos em 1997. Mauricio mudou-se para os Estados Unidos em 1999 após ganhar uma bolsa de estudos para estudar na Berklee College of Music, em Boston, MA. Ele se formou em Film Scoring, Performance and Arranging, e se formou na prestigiosa faculdade em 2002. Mauricio é um baterista e artista premiado e aclamado pela crítica. Desde sua mudança para Nova York em 2006, ele se tornou um dos bateristas mais requisitados da cena.\
<br><br> \
Frequentemente ativo como um líder de banda e compositor / arranjador, Maurício coordena e colabora com vários projetos, como o Dig Trio - com o guitarrista Gustavo Assis Brasil - que lançou seu primeiro CD em 2003, obtendo grandes comentários de críticos e do público em geral. \
Em 2009, Mauricio lançou seu primeiro álbum solo, intitulado 7 Lives , que inclui várias de suas composições e apresenta uma mistura única de jazz brasileiro, fusion e jazz, tocado por uma formação muito talentosa: \
Gustavo Assis Brasil (guitarra), Itaiguara Brandão (baixo), Milene Corso (voz), Brandi Disterheft (baixo), Dom Salvador (piano), Oriente Lopez (piano, flautas), Esperanza Spalding (baixo), Cidinho Teixeira (piano) e Rodrigo Ursaia (sax). \
Em 2011 Zottarelli lançou MOZIK - uma colaboração com o pianista Gilson Schachnik, e também com a participação de Gustavo Assis Brasil, Fernando Huergo e Yulia Musayelyan. O álbum recebeu elogios de muitas publicações importantes de jazz / música, como a revista Downbeat e All About Jazz. O prestigioso Jazz Station Blog, escrito pelo jornalista / produtor Arnaldo deSouteiro, considerou MOZIK o novo talento nº 1 de 2011 e incluiu o CD no TOP 15 de seus melhores de 2011. Mauricio também foi incluído na lista dos 10 melhores bateristas do ano com diferentes projetos desde 2011. \
Em 2014, Mauricio lançou mais uma colaboração - desta vez com a baixista Amanda Ruzza. O álbum chama-se “Glasses, No Glasses”, e é uma gravação ao vivo de improvisação livre, com o convidado especial, pianista e multi-instrumentista Léo Genovese. \
<br><br>\
Em 2012, Mauricio ganhou o prestigioso Prêmio da Música Brasileira, o mais importante prêmio de música do Brasil, com o artista Dom Salvador e seu álbum “The Art of Samba Jazz”. \
Em 2019, o álbum "Heart of Brazil"   do artista  Eddie Daniels rendeu uma indicação ao GRAMMY de Melhor Categoria de Jazz Latino. Mauricio está tocando bateria e percussão neste projeto, bem como no novo álbum de Eddie que será lançado em 2020. \
Zottarelli é creditado em quase 100 álbuns até agora em sua carreira. Tem trabalhado e partilhado palco com alguns dos mais importantes e notáveis artistas da atualidade, cruzando vários estilos musicais. Alguns deles são: \
Hiromi * Eliane Elias * Toquinho * Prasanna * Lee Ritenour * Dave Grusin * Esperanza Spalding * Eddie Daniels * Cristina Pato * Keiko Matsui * Richard Bona * Paquito D’Rivera * Richard Galliano * Chico Pinheiro * Cláudio Roditi * Joe Locke * Geoffrey Keezer * Mike Pope * Eldar * Ivan Lins * Carmen Souza * Chuck Loeb * Rosa Passos * Dom Salvador * Jovino Santos Neto * Cidinho Teixeira * Christos Rafalides * Romero Lubambo * Nilson Matta * Toninho Horta * Oriente Lopez * Filó Machado * Gustavo Assis Brasil * Itaiguara Brandão * Hendrik Meurkens * Reza Khan *  Carmen Cuesta * Bakithi Kumalo * Aline Muniz  *Steve Sandberg * Richard Boukas * Gabriel Espinosa * Dario Boente  *Matt King * Marcos Silva * Sergio Pereira * Yunior Terry * Rafael Piccolotto de Lima & Chamber orchestra, entre muitos outros. \
'
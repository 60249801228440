import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { colorChange, focusPhoto, galleryChange } from 'src/app/animations/animations';
import { GalleryItemState } from 'src/app/enums/gallery-item-state.enum'
import { I18nMeta } from '@angular/compiler/src/i18n/i18n_ast';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { ModalService } from '../modal/modal.service';
import { BIO_DIEGO_GIL } from '../../bios/bio-diego-gil';
import { BIO_CAIO_DOHOGNE } from '../../bios/bio-caio-dohogne';
import { BIO_BRUNA_BARONE } from '../../bios/bio-bruna-barone';
import { BIO_FABIO_MARRONE } from '../../bios/bio-fabio-marrone';
import { BIO_FABIANO_PAZ } from '../../bios/bio-fabiano-paz';
import { BIO_LUIS_PAGOTO } from '../../bios/bio-luis-pagoto';
import { BIO_RICARDO_BERTI } from '../../bios/bio-ricardo-berti';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export interface GalleryItem {
  path: string;
  name: string;
  focused: boolean;
  category: CourseCategory;
  bio: SafeHtml;
  instagramLink: string;
  offsetImage?: boolean;
  fixed?: boolean;
}


export enum CourseCategory {
  EXTENSION = 'EXTENSION',
  REGULAR = 'REGULAR'
}

@Component({
  selector: 'app-photo-gallery-regular',
  templateUrl: './photo-gallery-regular.component.html',
  styleUrls: ['./photo-gallery-regular.component.scss'],
  animations: [focusPhoto, colorChange, galleryChange],
})
export class PhotoGalleryRegularComponent implements OnInit {

  _activeCategory: CourseCategory;
  faInstagram = faInstagram;
  faTimes = faTimes;
  visible: boolean;

  CourseCategory = CourseCategory;

  @ViewChild('galleryContainer') galleryContainer: ElementRef;

  constructor(private breakpointService: BreakpointService,
    private modalService: ModalService,
    private sanitizer: DomSanitizer) { }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  cards: CourseCard[] = [
    {
      clicked: false,
      hovered: false,
      redTitle: "cursos",
      grayTitle: "regulares",
      category: CourseCategory.REGULAR
    },
    {
      clicked: false,
      hovered: false,
      redTitle: "cursos",
      grayTitle: "de extensão",
      category: CourseCategory.EXTENSION
    },
    {
      clicked: false,
      hovered: false,
      redTitle: "práticas",
      grayTitle: "de banda",
      category: CourseCategory.REGULAR
    }
  ];

  teachers: GalleryItem[] = [
    {
      name: "Diego Gil",
      path: "assets/professores/diego_gil_2.jpg",
      fixed: true,
      offsetImage: true,
      focused: false,
      category: CourseCategory.EXTENSION,
      bio: this.sanitizer.bypassSecurityTrustHtml(BIO_DIEGO_GIL),
      instagramLink: 'https://instagram.com/diego_gil_baterista'
    },
    {
      name: "Caio Dohogne",
      path: "assets/professores/caio_dohogne.jpg",
      focused: false,
      offsetImage: true,
      category: CourseCategory.EXTENSION,
      bio: this.sanitizer.bypassSecurityTrustHtml(BIO_CAIO_DOHOGNE),
      instagramLink: 'https://instagram.com/caio_dohogne'
    },
    {
      name: "Luis Pagoto",
      path: "assets/professores/luis_pagoto.jpg",
      offsetImage: true,
      focused: false,
      category: CourseCategory.EXTENSION,
      bio: this.sanitizer.bypassSecurityTrustHtml(BIO_LUIS_PAGOTO),
      instagramLink: 'https://instagram.com/luispagoto'
    },
    {
      name: "Ricardo Berti",
      path: "assets/professores/ricardo_berti.jpg",
      focused: false,
      offsetImage: true,
      category: CourseCategory.EXTENSION,
      bio: this.sanitizer.bypassSecurityTrustHtml(BIO_RICARDO_BERTI),
      instagramLink: 'https://instagram.com/ricardo.berti_1'
    },

    {
      name: "Fabiano Paz",
      path: "assets/professores/fabiano_paz.jpg",
      focused: false,
      category: CourseCategory.EXTENSION,
      offsetImage: true,
      bio: this.sanitizer.bypassSecurityTrustHtml(BIO_FABIANO_PAZ),
      instagramLink: 'https://instagram.com/fabianopazbatera'
    },
    {
      name: "Bruna Barone",
      path: "assets/professores/bruna_barone.jpg",
      focused: false,
      offsetImage: true,
      category: CourseCategory.EXTENSION,
      bio: this.sanitizer.bypassSecurityTrustHtml(BIO_BRUNA_BARONE),
      instagramLink: 'https://instagram.com/baronebru'
    },
    {
      name: "Fabio Marrone",
      path: "assets/professores/fabio_marrone.jpg",
      focused: false,
      offsetImage: true,
      category: CourseCategory.EXTENSION,
      bio: this.sanitizer.bypassSecurityTrustHtml(BIO_FABIO_MARRONE),
      instagramLink: 'https://instagram.com/fabio_marrone'
    },



  ]

  ngOnInit(): void {
    this.activateCard(this.cards[0]);
  }

  shuffleArray(array: GalleryItem[]) {
    for (let i = array.length - 1; i > 1; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }

    return array;
  }

  @HostListener('window:scroll', ['$event'])
  getScroll() {
    const element = this.galleryContainer.nativeElement;
    var position = element.getBoundingClientRect();

    // checking for partial visibility
    if (position.top >= 0 && position.bottom <= window.innerHeight) {
      this.visible = true;
    }
  }

  redirectToSocialNetwork(link: string) {
    window.open(link);
  }

  handleMouseEnter(item: GalleryItem) {
    item.focused = true;
  }

  get isMobile() {
    return this.breakpointService.isMobile();
  }

  getGalleryChangeStatus(item: GalleryItem) {
    return item.category === this.activeCategory;
  }

  getWidth() {
    return (window.innerWidth * 90) / 100;
  }

  getHeight() {
    return (window.innerHeight * 50) / 100;
  }

  handleMouseLeave(item: GalleryItem) {
    item.focused = false;
  }

  isFocused(item: GalleryItem) {
    return item.focused ? GalleryItemState.HOVERED : GalleryItemState.UNHOVERED
  }

  get activeCategory(): CourseCategory {
    return this._activeCategory;
  }

  set activeCategory(category: CourseCategory) {
    this._activeCategory = category;
  }

  activateCard(card: CourseCard) {
    this.cards.forEach(card => {
      card.clicked = false;
    })

    card.clicked = true;
    this.activeCategory = this.cards.filter(x => x.clicked).map(x => x.category).find(x => x);
  }



}

export interface CourseCard {
  hovered: boolean;
  clicked: boolean;
  redTitle: string;
  grayTitle: string;
  category: CourseCategory;
}

import { Component, OnInit } from '@angular/core';
import { faPhone, faEnvelope, faMapPin } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import {BreakpointService} from 'src/app/services/breakpoint.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  faPhone = faPhone;
  faEnvelope = faEnvelope;
  faInstagram = faInstagram;
  faFacebook = faFacebook;
  faYoutube = faYoutube;
  faMapPin = faMapPin;
  constructor(private breakpointService: BreakpointService) { }

  ngOnInit(): void {
  }

  get isDesktop(){
    return this.breakpointService.isDesktop();
  }

  sendWhatsappMessage(){
    window.open("https://wa.me/5511944540293");
  }
}

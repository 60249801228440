export const BIO_DIEGO_GIL = 'Diego Gil iniciou seus estudos na bateria em 1986 aos 7 anos de idade, seus\
principais professores foram Tico Delisa, Giba Favery e Christiano Rocha.\
<br><br>\
Formado pelo IP&T (Institudo de Percussão e Tecnologia), Instituto onde hoje atua como professor e coordenador pedagógico.\
<br><br>\
Autor dos livros Explorando Diferentes Divisões sobre desenvolvimento de\
fraseado baseado em ritmos cruzados e deslocamento de figuras rítmicas, e também dos livros Quintinas e Septinas\
<br><br>\
Em 2017 lançou seu primeiro disco solo com músicas de sua própria autoria chamado Nave.\
O disco Nave conta com a participação de Claudio Machado, Chico Castellano, Lucas Esposito, José Renato Tavares e Mariana Galli.\
<br><br>\
Em 2018 lançou o curso online Tutorial e Play Along da Música वीयर - vīrya, onde ele ensina como tocar na bateria essa música do disco Nave. Em 2019 lançou o curso Afinação e Posicionamento para Bateristas.\
Diego Gil desenvolve um trabalho de pesquisa sobre música indiana.\
<br><br>\
<a href="https://www.youtube.com/watch?v=Vq51u43IEDw">Música Lucky 13 - Disco Melody of Rhythm by Fazal Qureshi and Pete Lockett</a>\
<br><br>\
<a href="https://www.youtube.com/watch?v=1RhVsvJAUpc">Música: Fill The Fives - Pete Lockett</a>\
<br><br>\
Patrocínios:\
<br>\
Peles Dudu Portes Signature by LUEN.\
<br><br>\
Já tocou com Sá e Guarabyra, Naíma, kleber Oliveira, Grupo RGF, Orquestra Jovem de Guarulhos, entre outros.\
<br>\
Acompanhou a cantora Rhaissa Bittar. Sua última turnê foi em Pequim - China.';
import { Component, OnInit } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { colorChange } from 'src/app/animations/animations';
import { GalleryItemState } from 'src/app/enums/gallery-item-state.enum';
import { ModalService } from '../../modal/modal.service';
import { GalleryItem } from '../../photo-gallery-regular/photo-gallery-regular.component';

@Component({
  selector: 'app-regular-courses',
  templateUrl: './regular-courses.component.html',
  styleUrls: ['./regular-courses.component.scss'],
  animations: [colorChange]
})
export class RegularCoursesComponent implements OnInit {

  graduation = 'O Formando apresenta um repertório de alto nível no auditório do IP&T, tocando estilos musicais \
  variados e tem o direito de fazer uma gravação de áudio e vídeo no estúdio Giba Favery \
  com captação profissional. \
  A Formatura também poderá ser transmitida \
  ao vivo no canal IP&T TV no youtube.';
  conclusion = 'O aluno recebe também, ao final de cada nível, um certificado de conclusão'

  faTimes = faTimes;

  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  regularCourseCards: RegularCourseCard[] = [
    {
      title: 'BÁSICO',
      subtitle: 'Básico 1 e Básico 2',
      teacher: 'Diego Gil, Fábio Marrone, Fabiano Paz, Bruna Barone, Luis Pagoto, Caio Dohogne e Ricardo Berti',
      focused: false,
      duration: '2 semestres (1 semestre por módulo)',
      classDuration: '1 aula por semana com 1h20” de duração',
      classParticipants: 'Grupos de até 4 alunos',
      methodology: [
        'história da bateria',
        'teoria',
        'leitura',
        'aquecimento e alongamento',
        'técnica (mãos e pés)',
        'postura e posicionamento',
        'coordenação',
        'estilos: rock, blues e balada',
        'frases (viradas)',
        'planejamento de estudo',
        'afinação',
        'repertório (play-alongs e transcrições)',
        'discografia indicada',
      ],
      hovered: false
    },
    {
      title: 'INTERMEDIÁRIO',
      subtitle: 'Módulo 1 ao Módulo 5',
      teacher: 'Diego Gil, Fábio Marrone, Fabiano Paz, Bruna Barone, Luis Pagoto, Caio Dohogne e Ricardo Berti',
      focused: false,
      duration: '5 semestres (1 semestre por módulo)',
      classDuration: '1 aula por semana com 1h20” de duração',
      classParticipants: 'Grupos de até 4 alunos',
      methodology: [
        'leitura',
        'técnica (mãos e pés)',
        'caixa e bumbo',
        'estilos: rock, funk, jazz, reggae, shuffle, blues e ritmos brasileiros',
        'ritmos afro-cubanos',
        'abertura de chimbal',
        'aplicação de paradiddle',
        'improviso e levada',
        'compassos quebrados,',
        'deslocamento e poliritmia',
        'notas fantasmas',
        'planejamento de estudo',
        'coordenação',
        'repertório (play-alongs e transcrições)',
        'discografia indicada',
      ],
      hovered: false
    },
    {
      title: 'AVANÇADO',
      subtitle: 'Módulo 6 ao Módulo 8',
      teacher: 'Diego Gil, Fábio Marrone, Fabiano Paz, Bruna Barone, Luis Pagoto, Caio Dohogne e Ricardo Berti',
      duration: '3 semestres (1 semestre por módulo)',
      classDuration: '1 aula por semana com 1h20” de duração',
      classParticipants: 'Grupos até 4 alunos',
      methodology: [
        'leitura',
        'rudimentos',
        'pedal duplo',
        'técnica (mão e bumbo)',
        'caixa e bumbo',
        'estilos: rock em compassos quebrados, jazz, funk, samba, vassourinha no jazz e na música brasileira, ritmos afro-cubanos e drum\'n\' bass',
        'coordenação',
        'interdependência',
        'ostinatos',
        'planejamento de estudo',
        'poliritmia',
        'repertório (play-alongs e transcrições)',
        'discografia indicada',
      ],
      hovered: false,
      focused: false,
    }
  ]

  handleMouseEnter(item: GalleryItem) {
    item.focused = true;
  }

  handleMouseLeave(item: GalleryItem) {
    item.focused = false;
  }

  isFocused(item: GalleryItem) {
    return item.focused ? GalleryItemState.HOVERED : GalleryItemState.UNHOVERED
  }

  courseParticipate() {
    window.open(this.buildWhatsappMessage());
  }

  buildWhatsappMessage(){
    const BASE_WHATSAPP_LINK = 'https://wa.me/5511944540293?text=';
    return `${BASE_WHATSAPP_LINK} Olá, tenho interesse em participar do curso regular do IPT`
  }
}
export interface RegularCourseCard {
  title: string;
  subtitle: string;
  hovered: boolean;
  focused: boolean;
  teacher: string;
  duration: string;
  classDuration: string;
  classParticipants: string;
  methodology: string[];
}
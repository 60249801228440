import { Component, OnInit, ElementRef, HostListener, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { colorChange, focusPhoto, galleryChange, rowsAnimation } from '../../animations/animations';
import { StepStatus } from '../../enums/step-status.enum';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
  animations: [rowsAnimation, focusPhoto, colorChange, galleryChange]
})
export class TimelineComponent implements OnInit {

  constructor(private elementRef: ElementRef) { }

  @ViewChildren("step") stepList: QueryList<ElementRef>;

  steps = [
    {
      title: 'giba favery',
      subtitle: 'direção geral',
      description: 'O IP&T Brasil faz 21 anos de cara nova. Mais moderno e renovado, o novo IP&T mudou\
      para oferecer o melhor no ensino de bateria e percussão para seus alunos. Agora em\
      novo endereço, a nova escola dirigida por Giba Favery conta com estrutura de ponta que\
      inclui salas de estudo equipadas com baterias acústicas e eletrônicas; hall de prática com\
      cabines individuais integradas com computador; sistemas informatizados; áreas de\
      convivência para alunos e professores e; um auditório equipado para gravações, eventos,\
      shows, lives, workshops e prática de banda.',
      status: StepStatus.INVISIBLE,
      imagePath: "assets/professores/giba_favery.jpg",
    },
    {
      title: 'diego gil',
      subtitle: 'coordenação - cursos regulares',
      description: 'Além da estrutura física de ponta o novo IP&T conta com o diferencial da metodologia única, testada e aprovada, reconhecida em prêmios internacionais, que formou gerações de grandes músicos hoje reconhecidos no mercado profissional. Alguns dos melhores profissionais formados pelo IP&T hoje fazem parte do seu corpo docente. São músicos altamente capacitados, com larga experiência didática e atuantes no mercado musical. O curso regular, coordenado pelo baterista Diego Gil, cumpre com seus objetivos de formar excelentes músicos profissionais e, também, excelentes bateristas amadores (Amador é aquele que pratica a arte apenas por amor).',
      imagePath: "assets/professores/diego_gil.jpg",
      status: StepStatus.INVISIBLE,
    },
    {
      title: 'guilherme marques',
      subtitle: 'coordenação - cursos de extensão',
      description: 'O IP&T inova e oferece juntamente a seu programa pedagógico regular, uma nova gama\
      de cursos temáticos de curta duração (de 6 a 8 aulas), aulas privadas, workshops e\
      oficinas intensivas, com alguns dos melhores e mais conceitos bateristas brasileiros: são\
      os cursos de extensão coordenados pelo baterista Guilherme Marques, que foram\
      estruturados para oferecer aos estudantes de diferentes níveis e estágios de\
      desenvolvimento, a possibilidade de aprofundamento em assuntos específicos que giram\
      em torno e complementam o curso regular do IP&T.',
      status: StepStatus.INVISIBLE,
      imagePath: "assets/professores/guilherme_marques.jpg",
    },
  ]

  ngOnInit(): void {
  }

  @HostListener('window:scroll', ['$event'])
  getScroll() {
    this.stepList.forEach((item, index, array) => {
      const element = item.nativeElement;
      var position = element.getBoundingClientRect();

      // checking for partial visibility
      if(position.top >= 0 && position.bottom <= window.innerHeight) {
        this.steps[index].status = StepStatus.VISIBLE;
      }
    })
  }
}



import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BreakpointService {

  constructor() { }

  isDesktop() {
    return document.documentElement.clientWidth > 992;
  }

  isTablet() {
    return document.documentElement.clientWidth >= 768 && document.documentElement.clientWidth <= 992;
  }

  isMobile() {
    return document.documentElement.clientWidth <= 767;
  }
}

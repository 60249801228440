export const BIO_BOB_WYATT = 'Mestre em “Linguística Aplicada e Estudos da Linguagem” pela Pontifícia Universidade Católica\
de São Paulo (PUCSP) e Graduação em Língua e Literatura Inglesas pela mesma Instituição, Bob\
Wyatt reside no Brasil desde 1981.\
<br>\
<br>\
De 1982 a 1984, fez parte do trio de Osmar Milito tocando na noite carioca. Durante esse\
período foi integrante da orquestra da primeira produção brasileira do musical, “Evita” (musical)\
de Andrew Lloyd Webber no teatro João Caetano. Em seguida, era integrante da orquestra de\
“Vargas” (musical) de Dias Gomes e Ferreira Gullar. Tocou com Paulo Moura, Leni de Andrade,\
grupo Pau Brasil (incl. Free Jazz Festival, 1985), João Donato, Maurício Einhorn (incl. Free Jazz\
Festival, 1985), Hélio Delmiro, Nivaldo Ornellas, Chet Baker (Free Jazz Festival, 1985) Idriss\
Boudrioua (Free Jazz Festival, 1986), Nelson Ayres, Proveta (Nailor Azevedo) e a Banda Savana,\
entre outros. Formou, em parceria com Alexandre Mihanovich, a Banda “re:bop”, em 1992, que\
realizou apresentações no SESC Paulista, Conservatório “Dr. Carlos de Campos” de Tatuí e em\
casas noturnas paulistanas, entre outros lugares.\
<br>\
<br>\
De 1999 a 2007 foi integrante da SoundScape Bigband, inaugurada em 1999 por um grupo de\
músicos apaixonados pelo som das big bands antigas e atuais. Organizada e coordenada por\
Junior Galante, Maurício de Souza e Bob Wyatt, a SoundScape é um grupo de profissionais\
altamente motivados pela procura da excelência musical e pelo desejo de preservar a arte e a\
técnica criadas pelos grandes nomes dessa forma de expressão musical. Neste mesmo período,\
Wyatt fez parte do Nelson Ayres Trio, cujas apresentações incluem destaques junto às\
Orquestras Jazz Sinfônica de São Paulo e Sinfonia Cultura.\
<br>\
<br>\
Com ampla discografia, Wyatt se apresenta regularmente com Bob Wyatt Quartet; Djalma Lima\
Quarteto, o Quinteto de Edson Santanna e a banda, “re:bop”.\
<br>\
<br>\
Lecionou estilos de jazz e técnicas de improvisação na Universidade Livre de Música Tom Jobim\
(atual EMESP, 2002) e foi professor da disciplina de Comunicação em Multimeios na PUC-SP\
(2003). Foi Orientador Artístico no Centro de Educação Unificada (CEU: unidades Rosa da China\
e Perus, 2004).\
<br>\
<br>\
Frequentemente convidado a lecionar em festivais de música, a escrever para Revistas\
especializadas e tendo trabalhos publicados em Anais de Congressos, Wyatt ainda integrou o\
corpo docente da Faculdade de Música Souza Lima (FSL, Brasil), lecionando as disciplinas de\
bateria e prática de grupo até 2017.\
<br>\
<br>\
Desde 2004, Bob é professor e Coordenador da área de música popular e Práticas musicais do\
Curso Superior de Música (Bacharelado) da Faculdade Cantareira.\
<br>\
<br>\
Carreira nos EUA\
<br>\
<br>\
Sua vida profissional se iniciou treze anos de idade em um conjunto que tocava em festas de\
casamento e bailes. A partir dos catorze anos começou a participar de uma big band patrocinada\
por um clube local. Em 1967 ingressou na Marinha dos EUA e, durante seu período de serviço,\
estudou na Armed Forces School of Music, na base de Little Creek, Virginia.\
<br>\
<br>\
Depois de várias tournées, incluindo shows nas ilhas do Caribe e países mediterrâneos,\
regressou aos EUA e ganhou uma bolsa para estudar no Departamento de Música da Memphis\
State University. Nesta época, participou de vários festivais regionais de jazz de bandas\
universitárias. Em 1973, Bob Wyatt recebeu o Prêmio de “Melhor Desempenho no instrumento”\
no American College Jazz Festival, importante festival nacional realizado no John F. Kennedy\
Center for the Performing Arts em Washington, DC.\
<br>\
<br>\
Atuou como palestrante e professor nos Seminários e cursos patrocinados pelo NAJE – National\
Association of Jazz Educators (organização originalmente formada para garantir a inclusão de\
jazz em programas de educação musical em todos os níveis e construir o respeito e a consciência\
para esta forma de arte). Fez parte de equipes que incluíam músicos como Clark Terry, Gary\
Burton, Frank Wess, Frank Foster e Bill Watrous.\
<br>\
<br>\
Em 1976 realizou tournée com a banda de Maynard Ferguson em vinte estados americanos e\
onze cidades no Japão. Em 1979 mudou-se para New York onde atuou em conjuntos de jazz que\
tocavam na noite novaiorquina. No verão daquele ano, estudou composição e improvisação no\
Creative Music Studio em Woodstock, NY, onde participou de Seminários e workshops dirigidos\
por Anthony Braxton, Roscoe Mitchell, Joseph Jarman, Jack DeJohnette e Carl Berger.'

<div class="course-card__container">
    <div class="row">
        <div class="col-xs-12 col-sm-6 col-lg-4" *ngFor="let card of regularCourseCards; let i = index">
            <app-modal [black]="true" class="course-card__modal" [id]="'curso-regular-'+i">
                <h5 class="red-title text-center mb-4">{{card.title | lowercase }} - {{card.subtitle | lowercase}}</h5>

                <div class="course-card__modal-close" (click)="closeModal('curso-regular-'+i)">
                    <fa-icon [icon]="faTimes" size="2x"></fa-icon>
                </div>

                <div class="bio-section">
                    <h5 class="red-title">professor(es)</h5>
                    <div class="mb-4" [innerHTML]="card.teacher"></div>

                    <h5 class="red-title">duração</h5>
                    <div class="mb-4" [innerHTML]="card.duration"></div>

                    <h5 class="red-title">duração da aula</h5>
                    <div class="mb-4" [innerHTML]="card.classDuration"></div>

                    <h5 class="red-title">turma</h5>
                    <div class="mb-4" [innerHTML]="card.classParticipants"></div>

                    <h5 class="red-title">metodologia</h5>
                    <ul *ngFor="let item of card.methodology">
                        <li>
                            {{item}}
                        </li>
                    </ul>
                    <div class="mb-4" [innerHTML]=""></div>

                </div>
            </app-modal>

            <div class="course-card__image-flip image-flip" ontouchstart="this.classList.toggle('hover');">
                <div class="course-card__mainflip mainflip">
                    <div class="course-card__frontside frontside">
                        <div class="course-card__card card">
                            <div class="course-card__card-body card-body text-center">
                                <p class="course-card__card-title card-title"
                                    [@colorChange]="card.hovered">
                                    {{card.title | uppercase}}</p>
                                <p class="course-card__card-subtitle card-subtitle">{{card.subtitle | uppercase}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="course-card__backside backside">
                        <div class="course-card__card card">
                            <div class="course-card__card-body card-body text-center">
                                <div class="row mb-2">
                                    <div class="col-12">
                                        <span class="course-card__card-title card-title mt-0">Professor(es)</span>
                                        <p class="course-card__card-text card-text">{{card.teacher}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <span class="course-card__card-title card-title mt-0">Duração</span>
                                        <p class="course-card__card-text card-text">{{card.duration}}</p>
                                    </div>
                                </div>

                            </div>
                            <a class="course-card__details-link mb-3" (click)="openModal('curso-regular-'+i)">mais
                                informações</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <a class="course-participate__link" (click)="courseParticipate()">
            <span>agende a sua avaliação</span>
        </a>
    </div>
</div>

export const BIO_IGOR_WILLCOX = 'Descendente de várias gerações de músicos, Igor teve como maior influência seu pai, Paulo César Willcox (maestro, arranjador, pianista e vibrafonista) e sua mãe, Sonia Willcox (cantora). \
<br><br>\
Igor tocou e gravou com importantes artistas da cena nacional e internacional, como: Jimmy Bo Horn, Cecilia Stalin, Guilherme Arantes, Família Lima, Sandy & Junior, Daniela Mercury, Rappin Hood, Fernanda Porto, Frejat, Gary Brown, entre outros. \
<br><br>\
Na música instrumental tocou e gravou com:  Bocato, Frank Colon (Weather Report), Tony Lakatos, Carlos Tomati, Michel Leme, Marcinho Eiras, Lea Freire, David Richards, Arismar do Espírito Santo, Walmir Gil, Jarbas Barbosa, Djalma Lima, Wilson Teixeira, Rubem Farias, Erik Escobar, Decebal Badila, Sandro Haick, Daniel Santiago, Marcelo Coelho, Glecio Nascimento, Fernando Rosa, Sidiel Vieira, Rodrigo Ursaia, entre outros. \
<br><br>\
Realizou tour internacionais acompanhando artistas, fazendo workshops e com seu grupo Igor Willcox Quartet, em países como Canadá, Alemanha, Seria, Argentina, Bolivia, Uruguai,etc. \
<br><br>\
Seus três álbuns autorais tiveram excelentes resenhas nos mais importantes e populares sites de jazz do mundo, como: All About Jazz (EUA), World Music Report (Canadá), Clube de Jazz (Brasil) e Glissando (EUA). \
<br><br>\
“Os fãs de fusion e jazz-funk em particular irão apreciar o estilo de condução firme, implacável de Willcox e com algumas contribuições de bom gosto de seus colegas, é um passeio auspicioso deste baterista e compositor versátil."\
<br><br>\
 " Há evidências mais do que suficientes aqui para sugerir que Willcox pode reivindicar um lugar na mesa entre os bateristas atuais, continuando a inspirar eficazmente os abundantes recursos do jazz fusion.“ -  Troy Dostert - All About Jazz. \
 <br><br>\
Os discos também foram indicados entre os melhores discos de 2017 e 2019, pelo conceituado jornalista e crítico musical, Carlos Calado (Folha de SP). \
<br><br>\
Além disso, o artista saiu na lista dos melhores instrumentistas de 2017, segundo o site "Melhores da Musica Brasileira”, ao lado de grandes nomes como, Hermeto Pascoal e João Donato. \
<br><br>\
Seu álbum com o trio "New Samba Jazz", lançado pelo selo suiço Altrisuoni Records, teve excelentes críticas na Europa, Ásia, EUA e Brasil, ficando como Best Seller da label durante 4 anos. \
<br><br>\
Importantes Festivais / Prêmios : \
<br>\
Batuka III (festival de bateria organizado pelo Instituto de Bateria Vera Figueiredo), ganhou na categoria musicalidade, em 1998. \
<br>\
Abertura do Batuka IV, ao lado de nomes como Dave Weckl e Virgil Donati. \
<br>\
Foi indicado pela revista Batera & Percussão como baterista revelação no ano 2000. \
<br>\
Se apresentou na 1ª edição do Batukinha, onde também tocaram Cuca Teixeira, Vera Figueiredo, Sam Aliano, Marco Minniman, Jim Chapin e Dom Famularo. \
<br>\
Se apresentou no 2° Modern Drummer Festival (Brasil). \
<br>\
Realizou workshops na Alemanha tendo como alguns mais importantes a Hochschule für Musik und Darstellende Kunst Frankfurt (Universidade de Musica de Frankfurt), Hochschule für Musik und Darstellende Kunst Hamburg ( Universidade de música de Hamburgo) , entre outros. \
<br>\
Tocou no IV Modern Drummer IP&T Festival.\
<br>\
Foi colunista da revista Batera & Percussão durante 1 ano.\
<br>\
Se apresentou no Primeiro Zildjian Camp Brasil (2015).\
<br>\
Santos Jazz Festival 2017 (Igor Willcox Quartet)\
<br>\
Play Jazz Festival 2017 (Igor Willcox Quartet)\
<br>\
SP Jazz Festival 2018 (Igor Willcox Quartet) \
<br>\
Nagano Drum Fest 2018\
<br>\
Festival de Jazz Jardins Suspensos da Babilonia (Igor Willcox Quartet)\
<br>\
North City Jazz Festival 2019 (Igor Willcox Quartet)\
<br>\
Festival de Inverno de Domingos Martins (shows e oficinas de bateria).\
<br>\
<br>\
Igor Willcox é endorsee das marcas Nagano Drums, Zildjian Cymbals, Korg, peles Luen, Pad Gorilla e Power Click.\
<br>\
Atualmente Igor Willcox se dedica aos seus projetos de música instrumental “Musical Experiments” e “Igor Willcox Quartet”,  além de produzir e se apresentar com seus projetos de música eletrônica WallBreakers.';
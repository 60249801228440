export const BIO_LUIS_PAGOTO = "Luis Pagoto estudou no CLAM (ZIMBO TRIO) com Lelo Izar e Ricardo Vargas, Duda Neves, Zé Eduardo Nazário, ULM com Beto Caldas e Renato Martins e IP&T com Giba Favery.  \
<br><br>\
Ministrou aulas no CLAM (Santos-SP), Escola Duda Neves, IP&T, além de aulas particulares. \
<br><br>\
Já trabalhou com Zé Geraldo, Lady Zu, Tutti Baê, Sérgio Duarte, Mozart Melo, Sergai, Vivi Keller Samara, Rubens Sales, Soul Fine, Sergio Duarte, Descontrasamba/Puramor, Poesia de nos Dois, Shirley Carvalho, Marcia Cherubim, Rodrigo e Marilton Borges Willie de Oliveira e outros. \
<br><br>\
Fez participação em shows e Workshops ao lado de Luiz Melodia, Jane Duboc, Roberto Frejat, , Wander Taffo, Nahor Gomes, Ubaldo Versollato, Vítor Alcântara, Cris Machado , Nilton Wood, Mauro Hector,  Mônica Marssola , Claudio Machado , João Cristal , Marcelo Tangari ,Alexandre Bessa , , Dino Baroni ,  Dito Branco, e projeto Ateliê Banda (Paulo Moura) e Atualmente, faz parte do Medusa Trio. \
<br><br>\
Participou de gravações de CD's dos artistas Márcio Okayama, Rubens Sales (Munderno), Cláudio Lima (Cada mesa É um palco), Manuel Filho (Tempo), Tutti Baê (Mosaico e Sensatez ),Cris Lemos,  Puramor, Jaburu, R-13, Medusa Trio,  Irlana Maia, João Rock,  Márcia Querubim(Canto Forte e Vai em casa)Pedro Carlos e  Patrícia Nabelo. \
<br><br>\
Também esteve nos Festivais: Modern Drummer, FNAC, Briston, em Londres, Santos Jazz, IG&T, IT&T, IB&T e IC&T, além de apresentações na televisão, como: Jô Soares, Programa Livre, Viola Minha Viola, Bem Brasil, Senhor Brasil e outros.  \
<br><br>\
Realizou trabalhos com artistas nos seguintes programas de TV: Programa do Jô (Lady Zu, Xandra Joplin e Medusa Trio), Bem Brasil, Programa Livre (Tuti Baê), Viola Minha Viola (Samara), Marcos Mion- Descontrole (Lady Zu), Barlada NGT(Irlana Maia) entre outros.";
export const BIO_LILIAN_CARMONA = ' Lilian Carmona, baterista paulistana, apresenta um currículo brilhante de uma sólida carreira nacional e internacional. Estudou na Berklee College of Music (Boston /USA) e na escola de bateria de Dante Agostini/Kenny Clarke (Paris/ França). \
 <br><br>\
No decorrer de sua carreira, acompanhou e gravou com artistas de expressão como Jorge Calandrelli, Cesar Camargo Mariano, Paquito D’Riviera, Cláudio Roditi, Leny Andrade, Les Elgarts, Phil Wilson, Jane Duboc, Dominguinhos, Jon Hendricks, Michel Legrand, George Shearing, Peri Ribeiro, Marcio Montarroyos, Luiz Eça, The Platters, Billy Eckstine, Nara Leão, Gal Costa, Alcione, Angela Ro Ro, Baden Powell, Nana Caimmy, Lucinha Lins, Filó, Márcia, Célia, Zé Luiz Mazziotti, Eduardo Conde, Carmen MacRae, entre outros.\
<br><br>\
Apresentou-se na  Sala São Paulo como  baterista convidada da Royal Philarmonic Orchestra of London, sob a regência de Ettore Stratta e com Diana King sob a regência de João Carlos Martins. Como solista, participou do Tributo a Gilberto Gil, sob regência de Mônica Giardini. \
<br><br>\
Atuou em vários musicais da Broadway , entre eles “Vitor ou Vitória“ , “A Bela e a Fera“, “Sweet Charity”, “Os Produtores”, “Hair Spray”, “Pernas Pro Ar” e do workshop do musical “ Chicago”.\
<br><br>\
Na carreira acadêmica, Lilian integrou o corpo docente de instituições de renome como Unicamp e Festival de Inverno de Campos de Jordão.\
<br><br>\
Atualmente, ministra workshops de bateria e leitura rítmica, e lidera uma banda instrumental.';
export const BIO_SANDRO_HAICK = 'Sandro Haick é multi-instrumentista, compositor, arranjador, professor, engenheiro de áudio, diretor e produtor musical. \
<br><br>\
Quando Sandro, aos 2 anos de idade, ganhou uma bateria de seu pai Netinho, baterista da banda *Os Incríveis*, começou a ter um contato diário com a música. Assim, aprendeu a falar, andar e tocar ao mesmo tempo, despertando uma curiosidade insaciável por todos os instrumentos e ritmos que encontrava. \
<br><br>\
Aos 8 anos de idade, Sandro já era um músico profissional, participando de shows e gravações junto ao seu pai, por todo o Brasil. Alguns anos depois, tendo então completado 11 anos, Sandro fundou a banda *Bom-Bom* que lançou a música “Vamos a La Playa”, um hit que ficou em primeiro lugar nas paradas de sucesso por 6 meses – foram dois anos intensos com muitos shows e programas de televisão. \
<br><br>\
No entanto, Sandro crescia prodigiosamente como profissional e músico, e percebeu que o sistema de educação convencional não lhe proporcionava um ambiente propício para o seu desenvolvimento artístico. Netinho apoiou sua saída da escola convencional. Sandro passou a destinar todo o seu tempo ao estudo da música em todos os seus aspectos, e seguiu crescendo em sua carreira artística. \
<br><br>\
Sandro passou a atuar em diversas especialidades como profissional musical – tocando ao vivo em bandas de baile, grupos de rock e jazz instrumental; gravando em produções fonográficas; dando aulas; e produzindo jingles e trilhas de publicidade. \
<br><br>\
Foi trabalhando com o mestre da sanfona Dominguinhos, que Sandro descobriu sua identidade musical e estilo próprios, mergulhando fundo na riqueza das diversas raízes regionais brasileiras. \
<br><br>\
Sandro Haick utiliza os seguintes instrumentos em performances, workshops, clínicas e gravações em estúdio: bateria, percussão, baixos elétricos e acústico, guitarra, violões de 6, 7 e 12 cordas, OUD (alaúde Turco), sitar (Indiano), pipa (Chinês), sanshin (Chinês), cavaco, bandolim, viola caipira, violão tenor, banjo bluegrass, ukulele, piano, teclados, além de desenvolver programação de samples, efeitos e loops no computador. \
<br><br>\
Em 2016, Sandro montou uma parceria com o produtor Maurício Machado e o antropólogo Rodrigo Ferrari Nunes PhD, para lançar a plataforma online de estudos chamada O Segredo da Música, (www.osegredodamusica.com.br). Lá, Sandro tem aplicado o seu método original, conhecido pela fórmula *Ouvir, Cantar e Tocar*. É um método de aprendizado e ensino musical completo para todos os instrumentos e níveis, incluindo percussionistas e vocalistas. Milhares de alunos do mundo todo já passaram por essa plataforma, que cresce a cada dia. \
<br><br>\
Sandro Haick ministra palestras, oficinas e workshops pelo Brasil afora e no exterior. Já deu workshops na Itália, Estados Unidos, China, Uruguai e Portugal. Nestes, Sandro aborda temas como harmonia, melodia, ritmo, improviso, prática de grupo, composição, arranjo, e o repertório brasileiro. \
<br><br>\
Lançamentos & Parcerias \
<br>\
Em sua carreira artística solo, Sandro Haick lançou 11 álbuns – O mais recente, Universal (2019) [ iTunes | Spotify ], foi lançado em CD no Japão e na Europa pela gravadora Disc Union, e está disponível para download na Internet. Essa obra conta com os arranjos do grande Itiberê Zwarg, e com as participações especiais dos mestres Hermeto Pascoal e Raul de Souza. \
<br><br>\
Como Produtor e Diretor Musical \
<br>\
Sandro tem em seu portfólio mais de 100 trabalhos com destaque para os CDs e DVDs com Dominguinhos, e com a cantora Guadalupe. \
<br><br>\
Guadalupe “Dedicado a Você” Part. Especial Dominguinhos – 2016 (indicado Grammy Latino) \
<br>\
Dominguinhos DVD e CD “Iluminado” 2011 (Prêmio Grammy Latino)\
<br>\
Dominguinhos DVD “Ao Vivo em Fazenda Nova” 2009\
<br>\
Dominguinhos “Conterraneos” 2006 (Prêmio Tim da Música Brasileira)\
<br>\
<br>\
Sandro Haick é patrocinado exclusivo pelas marcas: \
<br>\
<br>\
Tagima Electric Guitars, Acoustic Guitars and Bass \
<br>\
Nagano Drums\
<br>\
Vater Drumsticks\
<br>\
Nig Strings\
<br>\
GNI Pedals\
<br>\
Santo Angelo Cables\
<br>\
Bosphorus Cymbals\
<br>\
Harmonik Instrument Microphone\
<br>\
Gavazzi Cases\
<br>\
Rotstage Tech Amps';
export const BIO_VINICIUS_BARROS = 'Vinícius Barros é Mestre em Percussão pelo Instituto de\
Artes da Unicamp (2015), Bacharel em Percussão pelo\
Instituto de Artes da Unesp (2001) e formado pelo\
Conservatório de Tatuí (1994). Estudou com importantes\
mestres da percussão como John Boudler, Carlos Stasi,\
Eduardo Gianesella, Marcos Suzano, Guello, Osvaldinho\
da Cuíca, Eduardo Contrera, entre outros.\
<br>\
<br>\
Chefe de naipe da Orquestra Jazz Sinfônica de São\
Paulo, onde atua como percussionista desde 1999,\
Barros já tocou com grandes nomes atuando pela\
Orquestra – Richard Bona, Deep Purple, John Pizzarelli,\
Dave Liebman, Regina Carter, Toninho Horta, Dori\
Caymmi, João Bosco e, em outros projetos podendo\
incluir Donny McCaslin, Ted Piltzecker, Victor Mendonza,\
Banda Savana, Banda Mantiqueira, Orquestra Popular de\
Câmara, Zizi Possi, Toquinho, Zé Renato, Osvaldinho do\
Acordeon, Carlos Malta entre outros.\
<br>\
<br>\
Integrante do grupo ‘Vana Trio plus Brazilian Percussion’\
do pianista alemão radicado em Nova York, Vana Gierig,\
desde 2002, como o qual tem se apresentando em\
importantes festivais nos Estados Unidos, Europa, Japão\
e Brasil. Desenvolve trabalho de pesquisa com ritmos\
tradicionais brasileiros, tendo ministrado clínicas em\
várias escolas em Portugal, México e Estados Unidos,\
Inglaterra, Finlândia e Japão.\
<br>\
<br>\
Em 2007 participou do 40 Festival Internacional de\
Percussão de Monterrey (México) no trabalho de duo com\
Rogério Boccato. Em 2008 participou de turnê de 28\
concertos nos Estados Unidos com a Philarmonia\
Brasileira, tendo Branford Marsalis como solista\
convidado. Em 2009 esteve no mais importante festival de\
jazz da Europa, o North Sea Jazz Festival tocando no\
grupo do saxofonista Paquito D’Rivera, vencedor do\
Grammy Awards. Em 2010, Barros ministrou workshops e \
tocou no FAJ – Festival Amazonas Jazz, no Savassi Jazz\
Festival (Belo Horizonte) e Villa Celimontana Jazz\
(Roma), todos com ‘Vana Trio’ e Paquito D’Rivera.\
Em 2015 esteve no Japão para workshops e concertos de\
lançamento do Cd “Making Memories” de Vana Gierig\
com participação especial de Paquito D’ Rivera, também\
foi professor convidado do l PERCUSSE realizado em\
Aracaju (SE).\
<br>\
<br>\
No ano de 2016 tocou no importante festival PROM’S no\
Royal Albert Hall junto da Big Band da Jazz Sinfônica +\
OSESP. Também esteve nos Estados Unidos fazendo 6\
workshops onde podemos incluir a Manhattan School of\
Music, New York University ambas em NY. Além de\
University of Massachussets e Rutgers University (NJ)\
entre outras.\
<br>\
<br>\
Em 2017, participou do l Congresso Brasileiro de\
Percussão da Unicamp onde mostrou seu trabalho sobre\
“O uso do tamborim por Mestre Marçal”, tema de seu\
mestrado.\
<br>\
<br>\
Em 2019 esteve ministrando clinicas de Percussão\
brasileira nos Estados Unidos, Inglaterra e Finlândia.\
Integra o corpo docente da Faculdade Souza Lima desde\
2012.'
